import React from "react";

import { env } from "configs/env";
import { MAP_TYPE } from "app/providers/business/MapProvider";

export function GoogleTileLayer({ GoogleLayer, type = MAP_TYPE.SATELLITE }) {
  return (
    <GoogleLayer
      useGoogMapsLoader={false}
      googleMapsLoaderConf={{ KEY: env.GOOGLE_MAP_API_KEY }}
      type={type}
      language="en"
    />
  );
}
