import styled from "styled-components/macro";

import { baseButtonStyle, BOX_SHADOW, centerStyle, COLORS, FONTS, WEIGHT } from "configs/styles/common";

import { getColorForValue } from "./getRiskColor";

export const ExpandButton = styled.button`
  ${baseButtonStyle};
  width: 32px;
  height: 28px;
  background-color: transparent;
  position: relative;
  transform: ${({ $isExpanded }) => $isExpanded && "rotate(180deg)"};

  &::after {
    position: absolute;
    content: "";

    --tap-increment: -8px;

    inset: var(--tap-increment);
  }

  & svg {
    height: 20px;
  }
`;

export const NumberCell = styled.div`
  display: flex;
  align-items: center;
  padding-left: var(--padding-left);
`;

export const RiskCell = styled.div`
  display: grid;
  place-items: center;
  border-radius: 50%;
  border: 1.5px solid;
  width: 24px;
  height: 24px;
  font-size: var(--12px);
  color: ${({ $value }) => getColorForValue($value)};
`;

export const ImageCell = styled.button`
  ${baseButtonStyle};
  height: 24px;
  width: 24px;
  color: ${COLORS.DARK.Secondary};
  box-shadow: ${BOX_SHADOW.NEW};
  border-radius: 3px;
  background-color: ${COLORS.LIGHT.MiniBackground};
  position: relative;

  &::after {
    position: absolute;
    content: "";

    --tap-increment: -8px;

    inset: var(--tap-increment);
  }

  & svg {
    height: 20px;
  }
`;

export const FlagsWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 6px;
  flex-wrap: wrap;
`;

export const Flag = styled.div`
  background-color: var(--background-color);
  border-radius: 50%;
  width: 24px;
  height: 24px;
  display: grid;
  place-content: center;
`;

export const FilterWrapper = styled.div`
  ${centerStyle};
  gap: 10px;
  margin-left: auto;

  @media (max-width: 900px) {
    margin-left: revert;
  }

  @media (max-width: 600px) {
    flex-wrap: wrap;
  }
`;

export const parcelsSpecificStyle = {
  placeholder: (provided, state) => ({
    ...provided,
    color: state.selectProps.isError ? COLORS.INPUT.AccentPlaceholder : "#cacaca",
    textTransform: "capitalize",
    fontWeight: WEIGHT.regular,
    fontFamily: FONTS.Main,
    overflow: "hidden",
    textOverflow: "ellipsis",
    whiteSpace: "nowrap",
    fontSize: "14px",
  }),
  option: (provided, state) => ({
    ...provided,
    fontSize: 16,
    cursor: "pointer",
    textTransform: "capitalize",
    color: COLORS.DARK.Secondary,
    display: "flex",
    alignItems: "center",
    gap: "12px",
    backgroundColor: state.isFocused ? "#fff0b096" : "transparent",
  }),
  menuList: (provided) => ({
    ...provided,
    padding: "0",
    overflowX: "hidden",
    maxHeight: 235,
  }),
  singleValue: (provided) => ({
    ...provided,
    fontWeight: 400,
    fontFamily: FONTS.Main,
    color: COLORS.DARK.Secondary,
    fontSize: "14px",
    textTransform: "capitalize",
    display: "flex",
    alignItems: "center",
    gap: "12px",
  }),
};
