import React, { useCallback, useEffect, useState } from "react";
import { Controller } from "react-hook-form";
import { useTranslation } from "react-i18next";

import { USA_STATES } from "services/constants/location/usa-states";
import { JAPAN_PREFECTURES } from "services/constants/location/japan-prefectures";
import { formatCityOptions } from "services/utils/helpers/formatCityOptions";
import { COUNTRY } from "services/constants/location/countries";
import { useIsDeviceType } from "services/hooks/useIsDeviceType";

import { Select } from "../../inputs/Select";
import { CustomInput } from "../../inputs/CustomInput";
import * as Styled from "./style";

export const countryForms = {
  [COUNTRY.us]: FormUSA,
  [COUNTRY.jp]: FormJapan,
};

function FormUSA({ control, errors, stateDefaultValue, isStateDisabled, updateSuggestions, suggestionsComponent }) {
  const [cityOptions, setCityOptions] = useState([]);
  const { t } = useTranslation("forms");
  const { isTablet } = useIsDeviceType();

  function onStateChange(field, value) {
    field.onChange(value);
    formatCityOptions(value, setCityOptions);
  }

  useEffect(() => {
    if (stateDefaultValue) {
      formatCityOptions(stateDefaultValue, setCityOptions);
    }
  }, [stateDefaultValue]);

  const onAddressChange = useCallback(
    (field, e) => {
      field.onChange(e);
      if (updateSuggestions) {
        updateSuggestions(e.target.value);
      }
    },
    [updateSuggestions]
  );

  return (
    <>
      <Controller
        control={control}
        name="state"
        render={({ field }) => (
          <Select
            {...field}
            aria-label="state switcher"
            ariaLiveMessages={{
              onFocus: () => "You are currently on the state switcher",
              onChange: ({ label }) => `State changed to ${label}`,
            }}
            aria-live="polite"
            placeholder={t("state")}
            defaultValue={stateDefaultValue}
            options={USA_STATES}
            value={USA_STATES.find((state) => state.value === field.value)}
            onChange={(value) => onStateChange(field, value)}
            isError={errors.state?.value}
            isDisabled={isStateDisabled}
          />
        )}
      />
      <Controller
        control={control}
        name="city"
        render={({ field }) => (
          <Select
            {...field}
            aria-label="city switcher"
            ariaLiveMessages={{
              onFocus: () => "You are currently on the city switcher",
              onChange: ({ label }) => `City changed to ${label}`,
            }}
            aria-live="polite"
            placeholder={t("city")}
            options={cityOptions}
            value={cityOptions.find((city) => city.value === field.value)}
            onChange={(value) => field.onChange(value)}
            isError={errors.city?.value}
          />
        )}
      />
      <Styled.AddressInputWrapper>
        <Controller
          control={control}
          name="address"
          render={({ field }) => (
            <CustomInput
              {...field}
              onChange={(e) => onAddressChange(field, e)}
              placeholder={isTablet ? t("address") : t("street, building number")}
              error={errors.address && t(errors.address.message)}
              errorTestId="address-error"
            />
          )}
        />
        {suggestionsComponent}
      </Styled.AddressInputWrapper>
    </>
  );
}

function FormJapan({ control, errors, ...props }) {
  const { t } = useTranslation("forms");

  return (
    <>
      <Controller
        control={control}
        name="prefecture"
        render={({ field }) => (
          <Select
            {...field}
            aria-label="prefecture switcher"
            ariaLiveMessages={{
              onFocus: () => "You are currently on the prefecture switcher",
              onChange: ({ label }) => `Prefecture changed to ${label}`,
            }}
            aria-live="polite"
            placeholder={t("prefecture")}
            options={JAPAN_PREFECTURES}
            value={JAPAN_PREFECTURES.find((prefecture) => prefecture.value === field.value)}
            onChange={(value) => field.onChange(value)}
            isError={errors.prefecture?.value}
            {...props}
          />
        )}
      />
      <Controller
        control={control}
        name="address"
        render={({ field }) => (
          <CustomInput
            {...field}
            placeholder={t("address")}
            error={errors.address && t(errors.address.message)}
            errorTestId="address-error"
          />
        )}
      />
    </>
  );
}
