import React from "react";
import { flexRender } from "@tanstack/react-table";

import * as Styled from "../style";

export function TableHeader({ table }) {
  return (
    <Styled.THead>
      {table.getHeaderGroups().map((headerGroup) => (
        <Styled.GridTHeaderRow key={headerGroup.id}>
          {headerGroup.headers.map((header) => {
            const minWidth = header.getSize();
            const justifyContent = header.column.columnDef.align;
            const headerContext = header.getContext();

            return (
              <Styled.GridHeadCell
                key={header.id}
                style={{
                  "--justify-content": justifyContent,
                  "--min-width": minWidth,
                }}
              >
                <Styled.GridHeadCellInner>
                  <Styled.GridHeadCellTitle>
                    {flexRender(header.column.columnDef.header, headerContext)}
                  </Styled.GridHeadCellTitle>
                </Styled.GridHeadCellInner>
              </Styled.GridHeadCell>
            );
          })}
        </Styled.GridTHeaderRow>
      ))}
    </Styled.THead>
  );
}
