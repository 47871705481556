import React from "react";
import { useTranslation } from "react-i18next";

import * as Styled from "./style";

export function FilterButton() {
  const { t } = useTranslation("parcels-table");

  return <Styled.FilterButton>{t("filter")}</Styled.FilterButton>;
}
