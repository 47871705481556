import React from "react";

import { ParcelsTable } from "../ParcelsTable";
import { ParcelsTableProvider } from "../../store/ParcelsTableProvider";

export function TableTab() {
  return (
    <ParcelsTableProvider>
      <ParcelsTable />
    </ParcelsTableProvider>
  );
}
