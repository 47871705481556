import { Libraries } from "@react-google-maps/api";

import { env } from "configs/env";


interface MapLoader {
  googleMapsApiKey: string;
  libraries: Libraries;
  language: string;
}

export const MAP_LOADER: MapLoader = {
  googleMapsApiKey: env.GOOGLE_MAPS_API_KEY,
  libraries: ["places", "drawing"],
  language: "en",
};
export const MAP_LOADER_WITHOUT_LIBRARIES = {
  googleMapsApiKey: env.GOOGLE_MAPS_API_KEY,
  language: "en",
};
