import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { MagnifyingGlassIcon } from "@heroicons/react/24/outline";

import { InputWrapper, searchInputStyle } from "./style";
import { CustomInput } from "../../../../inputs/CustomInput";

export function SearchTableInput({ onTableSearch, testId = "search-table" }) {
  const { t } = useTranslation();
  const [filter, setFilter] = useState("");

  useEffect(() => {
    onTableSearch(filter);
  }, [filter, onTableSearch]);

  return (
    <InputWrapper>
      <CustomInput
        data-testid={testId}
        type="text"
        value={filter}
        onChange={(e) => setFilter(e.target.value)}
        placeholder={t("Search address")}
        icon={<MagnifyingGlassIcon />}
        clearValue={filter.length >= 2 ? () => setFilter("") : null}
        style={searchInputStyle}
      />
    </InputWrapper>
  );
}
