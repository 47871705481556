import React, { createContext, useMemo, useState } from "react";

export const ParcelsTableContext = createContext();

export function ParcelsTableProvider({ children }) {
  const [selectedAttribute, setSelectedAttribute] = useState(null);
  const [selectedData, setSelectedData] = useState(null);

  const value = useMemo(
    () => ({ selectedAttribute, setSelectedAttribute, selectedData, setSelectedData }),
    [selectedAttribute, selectedData]
  );

  return <ParcelsTableContext.Provider value={value}>{children}</ParcelsTableContext.Provider>;
}
