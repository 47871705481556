import React from "react";
import { useTranslation } from "react-i18next";

import { PopoverContent } from "components/presenters/common/Popover";

import { Loader } from "../../Loader";
import { NotificationItem } from "./NotificationItem/NotificationItem";
import * as Styled from "../ui/style";

export function NotificationList({ isLoading, isError, notifications = [] }) {
  const { t } = useTranslation();

  let content;

  if (isError) {
    content = (
      <Styled.Message>{t("An error occurred while fetching notifications. Please try again later.")}</Styled.Message>
    );
  } else if (isLoading) {
    content = <Loader isLoading={isLoading} position="absolute" />;
  } else if (notifications.length > 0) {
    content = notifications.map((notification) => (
      <NotificationItem key={notification.id} notification={notification} />
    ));
  } else {
    content = <Styled.Message>{t("You don't have any notifications")}</Styled.Message>;
  }

  return (
    <PopoverContent sideOffset={10}>
      <Styled.ListWrapper>{content}</Styled.ListWrapper>
    </PopoverContent>
  );
}
