import { env } from "configs/env";

export const DEFAULT_MAP_ZOOM = 19;
export const MAX_ZOOM_LEVEL = 21;

export const TILE_LAYER = Object.freeze({
  SATELLITE: "satellite",
  WHITE: "white",
  STREET: "street",
});

export const tileLayerUrl = {
  [TILE_LAYER.SATELLITE]: `https://api.mapbox.com/styles/v1/mapbox/satellite-v9/tiles/256/{z}/{x}/{y}@2x?access_token=${env.MAPBOX_TOKEN}`,
  [TILE_LAYER.WHITE]: `https://api.mapbox.com/styles/v1/mapbox/light-v9/tiles/256/{z}/{x}/{y}@2x?access_token=${env.MAPBOX_TOKEN}`,
  [TILE_LAYER.STREET]: `https://api.mapbox.com/styles/v1/mapbox/streets-v11/tiles/256/{z}/{x}/{y}@2x?access_token=${env.MAPBOX_TOKEN}`,
};

export const GENERAL_TILE_ATTRIBUTION = 'Map data &copy; <a href="https://www.mapbox.com/">Mapbox</a>';
