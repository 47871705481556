import { useState, useEffect } from "react";

export function useDebouncedValue(value, delay = 300) {
  if (value === undefined || value === null) {
    throw new Error("The 'value' parameter is required and cannot be undefined or null.");
  }

  const [debouncedValue, setDebouncedValue] = useState(value);

  useEffect(() => {
    const handler = setTimeout(() => {
      setDebouncedValue(value);
    }, delay);

    return () => {
      clearTimeout(handler);
    };
  }, [value, delay]);

  return debouncedValue;
}
