import React from "react";
import { InfoBox } from "@react-google-maps/api";

import { InfoWrapper, InfoName } from "../../style";

export function CustomInfoBox({ position, info, fontSize = "12px" }) {
  if (!info) {
    return null;
  }

  return (
    <InfoBox position={position} options={{ disableAutoPan: true }}>
      <InfoWrapper>
        <InfoName fontSize={fontSize}>{info}</InfoName>
      </InfoWrapper>
    </InfoBox>
  );
}
