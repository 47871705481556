import React from "react";
import { ChatBubbleBottomCenterTextIcon } from "@heroicons/react/24/outline";

import * as Styled from "./style";

export function CommentButton() {
  return (
    <Styled.ActionButton>
      <ChatBubbleBottomCenterTextIcon />
    </Styled.ActionButton>
  );
}
