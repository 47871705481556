import styled from "styled-components/macro";

import { COLORS, WEIGHT } from "configs/styles/common";
import Button from "components/presenters/common/Button/Button";

export const FilterButton = styled(Button)`
  height: 35px;
  width: 105px;
  color: ${COLORS.DARK.Secondary};
  padding: 0;
  box-shadow: 0 2px 2px 0 rgb(0 0 0 / 15%);
  flex-shrink: 0;
  font-size: var(--14px);
  font-weight: ${WEIGHT.semiBold};
`;
