import React from "react";

import { Popover } from "components/presenters/common/Popover";
import { PopupModal } from "components/_modals/AlertModals";
import { Modal } from "components/presenters/common/Modal";

import { NotificationList } from "../components/NotificationList";
import { NotificationButton } from "../components/NotificationButton";
import { usePushNotifications } from "../components/usePushNotifications";
import { useNotification } from "../components/useNotification";
import { usePopup } from "../components/usePopup";
import * as Styled from "./style";

export function Notification() {
  const {
    isLoading,
    isError,
    notifications,
    isNewNotification,
    setIsNewNotification,
    isNotificationListVisible,
    onFetchNotifications,
  } = useNotification();
  const { popupData, setPopupData, isPopupShown, setIsPopupShown, onPopupClose } = usePopup();

  usePushNotifications({ setIsNewNotification, setIsPopupShown, setPopupData });

  return (
    <>
      <Styled.Wrapper>
        <Popover open={isNotificationListVisible} onOpenChange={onFetchNotifications}>
          <NotificationButton isNewNotification={isNewNotification} visible={isNotificationListVisible} />
          <NotificationList isLoading={isLoading} isError={isError} notifications={notifications} />
        </Popover>
      </Styled.Wrapper>

      <Modal
        open={isPopupShown}
        content={PopupModal}
        close={onPopupClose}
        title={popupData?.notification_title}
        description={popupData?.notification_description}
        modalWidth="30%"
      />
    </>
  );
}
