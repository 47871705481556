import styled from "styled-components/macro";

import { QUERIES } from "configs/styles/common";

export const InputWrapper = styled.div`
  width: 270px;

  @media ${QUERIES.upToMobile} {
    width: 220px;
  }

  & input {
    color: #cacaca;
    box-shadow: 0 2px 2px rgb(0 0 0 / 15%);
    padding-top: 5px;
    padding-left: 43px;

    &::placeholder {
      text-transform: capitalize;
      color: #cacaca;
      font-size: 14px;
    }
  }

  & svg {
    height: 24px;
    color: #cacaca;
  }

  & div {
    height: 20px;
  }
`;

export const TopWrapper = styled.div`
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  row-gap: 12px;
  padding-block: 4px;
`;

export const searchInputStyle = { height: "36px" };
