import React from "react";

import { ReactComponent as CloseSVG } from "assets/icons/close_icon_dark.svg";

import * as Styled from "./style";

import "reactjs-popup/dist/index.css";

// Use it only when you don't have a trigger button for opening a modal, or you don't want to open a modal each time the trigger button is clicked.
// otherwise, use:
// 1. AlertDialog component for modal confirmation dialog that interrupts the user and expects a response.
// 2. Dialog component for Modal dialog window displayed above the page (for general modals, without crucial interactions).
// 3. Popover component for modal dialog window displayed next to the trigger button that does not interrupt the user (for general modals, without crucial interactions).

export function Modal({ content: Content, open, close, modalWidth, ...rest }) {
  return (
    <Styled.StyledPopup
      open={open}
      modal
      closeOnDocumentClick
      closeOnEscape
      nested
      onClose={close}
      lockScroll
      modalWidth={modalWidth}
    >
      <Content close={close} {...rest} />
      <Styled.CloseButton onClick={close} data-testid="close-modal">
        <CloseSVG />
      </Styled.CloseButton>
    </Styled.StyledPopup>
  );
}
