import React from "react";
import { PlusIcon } from "@heroicons/react/24/outline";

import * as Styled from "./style";

export const AddFootprintButton = React.forwardRef((props, ref) => {
  return (
    <Styled.AddFootprint {...props} ref={ref}>
      <PlusIcon />
    </Styled.AddFootprint>
  );
});

AddFootprintButton.displayName = "AddFootprintButton";
