import styled from "styled-components/macro";

import { baseButtonStyle, BORDER_RADIUS, BOX_SHADOW, COLORS, WEIGHT } from "configs/styles/common";
import { ErrorSignImg } from "assets/images/shared";

import { text } from "../../presenters/common/Typography";

export const Wrapper = styled.label`
  width: 100%;
  display: block;
  position: relative;
`;

export const IconWrapper = styled.div`
  position: absolute;
  top: 0;
  bottom: 0;
  margin: auto 0;
  height: 16px;
  padding-left: 10px;
  cursor: pointer;
`;

export const TextInput = styled.input`
  ${text};
  width: 100%;
  border: none;
  padding-inline: var(--padding-inline);
  color: ${COLORS.DARK.Main};
  font-weight: ${WEIGHT.medium};
  box-shadow: ${BOX_SHADOW.LIGHT};
  border-radius: ${BORDER_RADIUS.XS};
  background-color: ${({ isError }) => (isError ? COLORS.INPUT.ErrorBg : COLORS.LIGHT.White)};
  appearance: none;
  transition: box-shadow 200ms;

  &::placeholder {
    font-weight: ${WEIGHT.regular};
    color: ${({ isError }) => (isError ? COLORS.INPUT.AccentPlaceholder : COLORS.INPUT.Placeholder)};
    font-size: ${({ lang }) => (lang === "ja" ? "var(--14px)" : "var(--16px)")};
  }

  &:focus {
    outline: 1.5px solid ${COLORS.DARK.Main};
  }

  &:hover {
    box-shadow: ${BOX_SHADOW.REGULAR};
  }
`;

export const DeleteWrapper = styled.button`
  ${baseButtonStyle};
  position: absolute;
  right: -3px;
  top: 0;
  bottom: 0;
  width: 40px;
  color: ${COLORS.GREY.Form};
`;

export const ErrorWrapper = styled(DeleteWrapper)`
  background-image: url(${ErrorSignImg});
  background-size: 85%;
  background-repeat: no-repeat;
  background-position: center;
  right: 0;
  top: 5px;
  z-index: 1;
`;
