import React from "react";
import { useTranslation } from "react-i18next";

import { ReactComponent as SortUpSVG } from "assets/icons/sort-up.svg";
import { ReactComponent as SortDownSVG } from "assets/icons/sort-down.svg";

import { StarIcon } from "../../../../common/StarIcon";
import { Tooltip } from "../../../../common/Tooltip";
import { VisuallyHidden } from "../../../../common/VisuallyHidden";
import * as Styled from "../style/style";

export function SortingCell({ setAddressOptions, cellName, sortKey, tooltipContent, displayTooltip }) {
  const { t } = useTranslation("tooltips");

  return (
    <Styled.AddressWrapper>
      <Tooltip content={<span>{tooltipContent}</span>} isEmpty={!displayTooltip}>
        <span>{cellName}</span>
      </Tooltip>

      <Tooltip content={<span>{t("Sort Descending")}</span>}>
        <Styled.SortingButton
          onClick={() => {
            setAddressOptions((prev) => ({ ...prev, sortKey, sortOrder: "down" }));
          }}
        >
          <SortDownSVG />
          <VisuallyHidden>{t("Sort Descending")}</VisuallyHidden>
        </Styled.SortingButton>
      </Tooltip>

      <Tooltip content={<span>{t("Sort Ascending")}</span>}>
        <Styled.SortingButton
          onClick={() => {
            setAddressOptions((prev) => ({ ...prev, sortKey, sortOrder: "up" }));
          }}
        >
          <SortUpSVG />
          <VisuallyHidden>{t("Sort Ascending")}</VisuallyHidden>
        </Styled.SortingButton>
      </Tooltip>
    </Styled.AddressWrapper>
  );
}

export function generateSortingColumn({
  accessorKey,
  setAddressOptions,
  sortKey,
  cellName,
  tooltipContent,
  displayTooltip = false,
}) {
  return {
    accessorKey,
    header: () => (
      <SortingCell
        setAddressOptions={setAddressOptions}
        sortKey={sortKey}
        cellName={cellName}
        tooltipContent={tooltipContent}
        displayTooltip={displayTooltip}
      />
    ),
  };
}

function FavoriteColumn({ row, isFavorite, onFavoriteButtonClick }) {
  const { t } = useTranslation("tooltips");
  return (
    <Tooltip content={<span>{isFavorite ? t("Remove from Favorite") : t("Add to Favorite")}</span>}>
      <StarIcon
        isFavorite={isFavorite}
        onClick={() => onFavoriteButtonClick(row.original.address_id, isFavorite)}
        width="33px"
        height="29px"
      />
    </Tooltip>
  );
}

export function generateFavoriteSortingColumn({
  accessorKey,
  setAddressOptions,
  sortKey,
  cellName,
  onFavoriteButtonClick,
}) {
  return {
    accessorKey,
    header: () => <SortingCell setAddressOptions={setAddressOptions} sortKey={sortKey} cellName={cellName} />,
    cell: ({ row }) => (
      <FavoriteColumn row={row} isFavorite={row.original.is_favorite} onFavoriteButtonClick={onFavoriteButtonClick} />
    ),
  };
}
