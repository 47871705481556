import { useCallback, useEffect, useState } from "react";

import { fakeChildren } from "../../../lib/fakeData";

export function useParcelsData(data) {
  const [parcelsData, setParcelsData] = useState([]);

  useEffect(() => {
    if (data) {
      const newParcels = data.pages.flatMap((page) => page.items);
      setParcelsData(newParcels);
    }
  }, [data]);

  const handleExpand = useCallback(async (row) => {
    if (!row.original.children) {
      const footprints = fakeChildren;

      setParcelsData((prevData) =>
        prevData.map((parcel) =>
          parcel.originalParcelId === row.original.originalParcelId ? { ...parcel, children: footprints } : parcel
        )
      );
    }
    row.toggleExpanded();
  }, []);

  return { parcelsData, handleExpand };
}
