import React from "react";

import { TabSwitcher } from "../TabSwitcher";
import { ActionButtons } from "../ActionButtons";
import { BreadcrumbNavigation } from "../BreadcrumbNavigation";
import * as Styled from "./style";

export function NavigationBar({ activeTab, setActiveTab }) {
  return (
    <Styled.NavigationBarWrapper>
      <Styled.NavigationBarContainer>
        <BreadcrumbNavigation />

        <TabSwitcher activeTab={activeTab} setActiveTab={setActiveTab} />

        <ActionButtons />
      </Styled.NavigationBarContainer>
    </Styled.NavigationBarWrapper>
  );
}
