import styled from "styled-components";

import { baseButtonStyle, COLORS } from "configs/styles/common";

export const Wrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 20px;
  justify-self: end;
`;

export const ActionButton = styled.button`
  ${baseButtonStyle};
  box-shadow: none;
  color: ${COLORS.DARK.Secondary};
  transition: opacity 250ms;

  & svg {
    height: 24px;
  }

  &:hover {
    opacity: 0.7;
  }
`;
