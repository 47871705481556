import React from "react";
import { useTranslation } from "react-i18next";

import { Select } from "components/inputs/Select";

import { parcelsSpecificStyle } from "../../utils/style";
import { useParcelsTableContext } from "../../store/useParcelsTableContext";
import { useAttributeOptions } from "../../hooks/useAttributeOptions";

export function AttributeSelect() {
  const { selectedAttribute, setSelectedAttribute } = useParcelsTableContext();
  const { t } = useTranslation("parcels-table");
  const ATTRIBUTE_OPTIONS = useAttributeOptions();

  return (
    <Select
      options={ATTRIBUTE_OPTIONS}
      value={selectedAttribute}
      onChange={setSelectedAttribute}
      placeholder={t("attribute")}
      minWidth={164}
      specificStyle={parcelsSpecificStyle}
    />
  );
}
