import styled from "styled-components/macro";

import {
  baseButtonStyle,
  BORDER_RADIUS,
  BOX_SHADOW,
  COLORS,
  iconButtonStyle,
  QUERIES,
  WEIGHT,
} from "configs/styles/common";
import { ErrorSignImg } from "assets/images/shared";

export const InputWrapper = styled.div`
  max-width: 50%;
  margin-top: 2px;

  @media ${QUERIES.upToTablet} {
    max-width: 100%;
  }

  @media ${QUERIES.upToMobile} {
    position: sticky;
    left: 0;
  }

  & input {
    &::placeholder {
      border-bottom: 1px solid ${COLORS.INPUT.Placeholder};
    }
  }
`;

export const DeleteWrapper = styled.button`
  ${baseButtonStyle};
  background-color: transparent;
  cursor: pointer;
  position: absolute;
  right: 0;
  top: 0;
  bottom: 0;
  margin: auto 0;
  width: 40px;
  height: 35px;

  & svg {
    height: 16px;
  }
`;

export const TableWrapper = styled.div`
  display: flex;
  flex-direction: column;
  padding-inline: 5px;
  height: 100%;
  gap: 10px;
  overflow: auto;
`;

export const TableContainer = styled.div`
  flex-grow: 1;
`;

export const Table = styled.table`
  border-collapse: collapse;
  border-spacing: 0;
  vertical-align: top;
  width: 100%;
  display: table;
  box-shadow: ${BOX_SHADOW.REGULAR};
  border-radius: ${BORDER_RADIUS.S};
  height: ${({ isEmpty }) => isEmpty && "100%"};

  @media ${QUERIES.upToMobile} {
    border-radius: ${BORDER_RADIUS.XS};
    box-shadow: ${BOX_SHADOW.LIGHT};

    tr > th:first-child,
    tr > td:first-child {
      position: sticky;
      z-index: 2;
      left: -15px;
      background-color: ${COLORS.LIGHT.White};
    }
  }
`;

export const THead = styled.thead`
  position: sticky;
  top: 0;
  z-index: 1;
`;

export const HeadCell = styled.th`
  padding: 16px 12px;
  display: table-cell;
  text-align: left;
  vertical-align: middle;
  font-weight: ${WEIGHT.semiBold};
  color: ${COLORS.DARK.Main};
  color: ${({ isOrange }) => isOrange && COLORS.ACCENT};
  font-size: 14px;
  font-size: ${({ lang }) => lang === "ja" && "12px"};
  line-height: 20px;
  white-space: nowrap;

  &:first-child {
    padding-left: 16px;
    border-top-left-radius: 8px;
  }

  &:last-child {
    border-top-right-radius: 8px;
  }
`;

export const TRow = styled.tr`
  border-bottom: 1px solid rgb(128 128 128 / 50%);
  background-color: ${COLORS.LIGHT.White};
  background-color: ${({ isMain }) => isMain && `${COLORS.LIGHT.Slate} !important`};

  tbody &:last-child {
    border-bottom: none;

    td {
      &:first-child {
        border-bottom-left-radius: 8px;
      }

      &:last-child {
        border-bottom-right-radius: 8px;
      }
    }
  }
`;

export const TData = styled.td`
  padding: 14px 12px;
  display: table-cell;
  text-align: left;
  vertical-align: middle;
  white-space: nowrap;
  font-size: 11px;
  font-size: ${({ lang }) => lang === "ja" && "9px"};
  line-height: 20px;

  &:first-child {
    padding-left: 16px;
  }
`;

export const AttachedTable = styled(Table)`
  & th {
    font-size: 12px;
  }

  & td {
    cursor: pointer;
    transition: 0.4s;
    font-size: 10px;
  }

  & tr:hover td {
    background-color: ${COLORS.LIGHT.Slate};
  }
`;

export const PaginationWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 7px;
  padding-bottom: 10px;
`;

export const ErrorText = styled.div`
  width: 100%;
  padding-top: 35px;
  padding-left: 20px;
  height: 100%;
  min-height: 200px;
`;

export const EditableInputWrapper = styled.div`
  position: relative;
  width: 100%;
`;

export const EditableInput = styled.input`
  min-width: ${({ columnName }) => (columnName === "ID" ? "35px" : "70px")};
  min-width: ${({ isAddress }) => isAddress && "180px"};
  width: 100%;
  border: none;
  background-color: ${({ isError }) => isError && COLORS.INPUT.ErrorBg};
  border-radius: 3px;
  padding: 4px 8px 4px 2px;
  box-shadow: ${BOX_SHADOW.REGULAR};
  padding-right: ${({ isError }) => isError && "25px"};
  padding-left: ${({ isError }) => isError && "8px"};

  &:focus {
    outline: 1.5px solid ${COLORS.ACCENT};
    outline-color: ${({ isError }) => isError && COLORS.INPUT.ErrorBg};
  }
`;

export const ErrorSign = styled.div`
  width: 20px;
  height: 24px;
  background-image: url(${ErrorSignImg});
  background-repeat: no-repeat;
  background-position: center center;
  background-size: 24px;
  position: absolute;
  top: 4px;
  right: 5px;
  cursor: pointer;
`;

export const EditableTableWrapper = styled.div`
  overflow: auto;
  width: 100%;
  height: 100%;
  min-height: 370px;
  padding: 8px;
`;

export const PaginationButton = styled.button`
  ${iconButtonStyle};
  width: 33px;
  height: 30px;
  flex-shrink: 0;
`;

export const TablePage = styled.div`
  min-width: 120px;
  text-align: center;

  @media ${QUERIES.upToTablet} {
    min-width: 100px;
  }

  & span {
    font-weight: ${WEIGHT.semiBold};
  }
`;

export const GridTable = styled(Table)`
  display: grid;
  table-layout: fixed;
  overflow: auto;
  position: relative;
  box-shadow: none;
  text-indent: 0;
`;

export const GridTableContainer = styled.div`
  flex-grow: 1;
  overflow: auto;
  height: 700px;
  position: relative;
`;

export const GridTBody = styled.tbody`
  position: relative;
  overflow: auto;
  height: var(--tbody-height);
`;

export const GridTRow = styled(TRow)`
  top: 0;
  position: absolute;
  border-bottom: none;
  margin-bottom: 7px;
  border-radius: ${BORDER_RADIUS.XS};
  display: flex;
  width: 100%;
  background-color: var(--background-color);
  transform: var(--transform);
`;

export const GridTData = styled(TData)`
  display: flex;
  align-items: center;
  width: 100%;
  font-size: var(--14px);
  padding-block: 10px;
  color: ${COLORS.DARK.Secondary};
  min-width: var(--min-width);
  justify-content: var(--justify-content);
`;

export const GridTHeaderRow = styled(TRow)`
  background-color: ${COLORS.LIGHT.Background};
  border-bottom: none;
  width: 100%;
  display: flex;
`;

export const GridHeadCell = styled(HeadCell)`
  display: flex;
  align-items: center;
  width: 100%;
  font-size: var(--17px);
  padding-block: 10px;
  color: ${COLORS.DARK.Secondary};
  min-width: var(--min-width);
  justify-content: var(--justify-content);
`;

export const GridHeadCellInner = styled.div`
  min-height: 24px;
`;

export const GridHeadCellTitle = styled.span`
  text-transform: capitalize;
`;
