import styled from "styled-components/macro";

import { BOX_SHADOW, COLORS, containerWidth, mobileContainerStyle, QUERIES } from "configs/styles/common";

import { navigationBarHeight } from "../../utils/consts";

export const NavigationBarWrapper = styled.div`
  width: 100%;
  background-color: ${COLORS.LIGHT.White};
  box-shadow: ${BOX_SHADOW.SUBHEADER};
  position: relative;
`;

export const NavigationBarContainer = styled.div`
  max-width: ${containerWidth()};
  margin: 0 auto;
  display: grid;
  justify-content: space-between;
  align-content: center;
  align-items: center;
  grid-template-columns: 1fr 280px 1fr;
  grid-template-rows: ${navigationBarHeight.laptop}px;
  column-gap: 20px;

  @media ${QUERIES.upToTablet} {
    grid-template-rows: ${navigationBarHeight.tablet}px;
    grid-template-columns: 1fr 160px 1fr;
  }

  @media ${QUERIES.upToMobile} {
    ${mobileContainerStyle};
    grid-template-columns: 1fr 120px 1fr;
    column-gap: 2px;
  }
`;
