import { PROJECT_TYPES } from "services/constants/projects/project-types";
import { env } from "configs/env";

export const ROUTE_PATHS = {
  HOME: "/home",
  ADMIN_LOGIN: "/admin/login",
  SIGN_IN: "/sign-in",
  VERIFY_EMAIL: "/verify-email/:token",
  REQUEST_SIGN_UP: "/request-sign-up",
  SIGN_UP: "/sign-up",
  COOKIE_NOTICE: "/cookie-notice",
  PRIVACY: "/privacy",
  TERMS: "/terms",
  CONTACT_US: "/contact-us",
  FORGOT_PASSWORD: "/forgot-password",
  RESET_PASSWORD: "/reset-password/:token",
  UNSUBSCRIBE: "/unsubscribe",
  ABOUT_US: "/about-us",
  PLATFORM: "/platform",
  NEWS_1: "/news/1",
  NEWS_2: "/news/2",
  NEWS_3: "/news/3",
  LINKEDIN: "/linkedin",
  NOT_FOUND: "/not-found-page",
  US_DB: "/us-db/:email?",

  // Admin Routes
  ADMIN: "/admin",
  ADMIN_MANAGE_PLANS: "/admin/manage-plans",
  ADMIN_MANAGE_USERS: "/admin/manage-users",
  ADMIN_MANAGE_COUPONS: "/admin/manage-coupons",
  ADMIN_MANAGE_PROJECTS: "/admin/manage-projects",
  ADMIN_CONTACT_US: "/admin/contact-us",
  ADMIN_FEEDBACK: "/admin/feedback",
  ADMIN_LOGIN_ACTIVITY: "/admin/analytics/login-activity",
  ADMIN_MANAGE_ORDERS: "/admin/manage-orders",
  ADMIN_BILLS: "/admin/bills",
  ADMIN_REPORTS: "/admin/reports",
  ADMIN_REPORTS_PREVIEW: ({ projectId }) => `/admin/reports/preview/${projectId}`,

  // Project Routes
  SINGLE_SEARCH: "/single-search",
  PROJECTS: "/projects",
  API_TEST: "/testing-api",

  // User Settings
  SETTINGS: "/settings/:tab",
  CHANGE_PASSWORD: "/change-password",

  // Dynamic Project Routes
  CREATED_PROJECT: ({ projectId, projectType }) => `/project/${projectType}/${projectId}/created`,
  COMPLETED_PROJECT: ({ projectId, projectType, tab = "addresses" }) =>
    `/project/${projectType}/${projectId}/completed/${tab}`,
  ADDRESS_VIEW_PAGE: ({ projectId, addressId, buildingNum }) =>
    `/address-view/${projectId}/${addressId}/${buildingNum}`,
  PDF_EXPORT: ({ projectId, addressId, projectType = PROJECT_TYPES.ADDRESS_ANALYSIS }) =>
    `/pdf-export/${projectType}/${projectId}/${addressId}`,
  SINGLE_SEARCH_RESULT: ({ projectId, addressId, buildingNum }) =>
    `/single-search/${projectId}/${addressId}/${buildingNum}`,

  // pages_v2 Routes
  PARCELS_TABLE: ({ id, tab = "table" }) => `/parcels/${id}/${tab}`,
};

export const IS_FEATURE_DISABLED = {
  DB_PAGE: env.FF_HIDE_USDB_PAGE === "true",
};
