import React from "react";
import { useParams } from "react-router-dom";

import MapCirclePdf from "assets/icons/map-circle-pdf.svg";
import MapCircleSelected from "assets/icons/map-circle-selected.svg";
import MapCircle from "assets/icons/map-circle.svg";

import { CurrentLocationMarker } from "../CurrentLocationMarker";

import "../style.css";

export function BuildingMarkers({ buildingPoints, isPdf, onMarkerClick }) {
  const { buildingNum } = useParams();

  function getMarkerURL(index) {
    if (isPdf) {
      return MapCirclePdf;
    } else if (index + 1 === parseInt(buildingNum)) {
      return MapCircleSelected;
    }
    return MapCircle;
  }

  return (
    <>
      {buildingPoints.map((point, index) => {
        return (
          <CurrentLocationMarker
            key={index}
            options={{
              label: {
                text: (index + 1).toString(),
                fontSize: "9px",
                className: `${isPdf ? "marker" : ""}`,
              },
              opacity: isPdf ? 0.7 : 1,
            }}
            markerURL={getMarkerURL(index)}
            position={{ lat: point?.[1]?.[0] || 0, lng: point?.[1]?.[1] || 0 }}
            onClick={() => onMarkerClick(index)}
          />
        );
      })}
    </>
  );
}
