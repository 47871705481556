import { startTracker, tracker } from "services/tracking/openreplayTracker";

export async function initializeTracker(response, isAdmin) {
  if (!tracker && !isAdmin) {
    await startTracker({
      userID: response.customer_id,
      metadata: {
        country: response.country,
        plan: response.curr_data_plan,
        credits: response.curr_data_plan_credits,
      },
    });
  }
}
