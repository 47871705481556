import styled from "styled-components/macro";

import { centerStyle, headerHeight, QUERIES } from "configs/styles/common";

import { navigationBarHeight } from "../../utils/consts";

export const MapWrapper = styled.div`
  --headersHeight: calc(${headerHeight.laptop}px + ${navigationBarHeight.laptop}px);

  position: absolute;
  inset: 0;
  top: var(--headersHeight);
  ${centerStyle};
  width: 100%;
  height: calc(100vh - var(--headersHeight));
  min-height: 100%;

  @supports (height: 1dvh) {
    height: calc(100dvh - var(--headersHeight));
  }

  @media ${QUERIES.upToTablet} {
    --headersHeight: calc(${headerHeight.tablet}px + ${navigationBarHeight.tablet}px);
  }
`;
