import React, { useRef, Suspense, useMemo } from "react";

import { COLORS } from "configs/styles/common";

import { getGeoJSONStyle, mapContainerStyle } from "./style";
import { MapLoadError } from "../CustomGoogleMap/components";
import { Loader } from "../../common/Loader";
import { useGeoRasterData } from "./hooks/useGeoRasterData";
import { DEFAULT_MAP_ZOOM, MAX_ZOOM_LEVEL } from "./consts";
import { useLeafletModules } from "./hooks/useLeafletModules";
import { safeJSONStringify } from "./helpers";
import { useAddGeoRasterLayer } from "./hooks/useAddGeoRasterLayer";
import { useMapCenter } from "./hooks/useMapCenter";
import { GoogleTileLayer } from "./components/GoogleTileLayer";

export function MapWithGeoTIFF({
  url,
  centerLat,
  centerLng,
  boundaries,
  shouldFetchGeoTIFF = true,
  mapZoom = DEFAULT_MAP_ZOOM,
  boundariesColor = COLORS.ACCENT,
}) {
  const mapRef = useRef();
  const getMapInstance = () => mapRef.current?.leafletElement;
  const mapCenter = useMemo(() => [centerLat, centerLng], [centerLat, centerLng]);
  const modules = useLeafletModules();

  const { georasterData, isError, isFetching } = useGeoRasterData({ url, isQueryEnabled: shouldFetchGeoTIFF });

  const isMapError = isError || !centerLat || !centerLng;
  const geoJSONStyle = useMemo(() => getGeoJSONStyle(boundariesColor), [boundariesColor]);

  useAddGeoRasterLayer({ mapInstance: getMapInstance(), georasterData, modules });
  useMapCenter({ mapInstance: getMapInstance(), mapCenter, mapZoom });

  if (isMapError) {
    return <MapLoadError />;
  }

  if (!modules) {
    return <Loader position="absolute" isLoading />;
  }

  const { Map, GeoJSON, GoogleLayer } = modules;

  const boundariesKey = boundaries ? safeJSONStringify(boundaries) : "no-boundaries";

  return (
    <Suspense fallback={<Loader position="absolute" isLoading />}>
      <Map
        center={mapCenter}
        zoom={mapZoom}
        maxZoom={MAX_ZOOM_LEVEL}
        style={mapContainerStyle}
        ref={mapRef}
        zoomControl={false}
        doubleClickZoom={false}
        attributionControl={false}
      >
        <GoogleTileLayer GoogleLayer={GoogleLayer} />

        {isFetching && <Loader position="absolute" isLoading />}
        {boundaries && <GeoJSON key={boundariesKey} data={boundaries} style={geoJSONStyle} />}
      </Map>
    </Suspense>
  );
}
