import React from "react";

import { flagsIcons } from "../../../utils/consts";
import * as Styled from "../../../utils/style";

export function FlagsCell({ flags }) {
  return (
    <Styled.FlagsWrapper>
      {flags &&
        flags.map((item, i) => {
          const flag = flagsIcons[item];

          return flag?.icon ? (
            <Styled.Flag style={{ "--background-color": flag.color }} key={i}>
              {flag.icon}
            </Styled.Flag>
          ) : null;
        })}
    </Styled.FlagsWrapper>
  );
}
