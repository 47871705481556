import React from "react";
import { XMarkIcon } from "@heroicons/react/24/solid";
import * as PopoverRadix from "@radix-ui/react-popover";

import * as Styled from "./style";

export const Popover = PopoverRadix.Root;

export const PopoverTrigger = PopoverRadix.Trigger;

export const PopoverContent = React.forwardRef(
  ({ children, sideOffset = 4, align = "center", isCloseButtonShown = true, isArrowShown = true, ...props }, ref) => {
    return (
      <PopoverRadix.Portal>
        <PopoverRadix.Content ref={ref} align={align} sideOffset={sideOffset} {...props}>
          {isArrowShown && <PopoverRadix.Arrow width={14} height={8} style={Styled.arrowStyle} />}
          {isCloseButtonShown && (
            <Styled.Close aria-label="Close">
              <XMarkIcon style={Styled.closeSvgStyle} />
            </Styled.Close>
          )}
          {children}
        </PopoverRadix.Content>
      </PopoverRadix.Portal>
    );
  }
);

PopoverContent.displayName = PopoverRadix.Content.displayName;
