import basicConfig from "api/basicConfig";

export const parcelsApi = {
  getParcels: ({ id, limit, page }) => {
    const params = { limit, page };
    return basicConfig
      .createRequest(`/v3/portfolios/${id}/parcels`, "GET", {}, null, false, params)
      .then((res) => res.data);
  },

  getParcelImage: ({ parcelId, signal }) => {
    return basicConfig
      .createRequest(`/v3/geolookup/image/parcel/${parcelId}`, "POST", {}, undefined, false, undefined, false, signal)
      .then((res) => res.data);
  },
};
