import React from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";

import { TabNavItem, TabNavList } from "components/presenters/common/Tabs";
import { ROUTE_PATHS } from "app/providers/router";
import { useIsDeviceType } from "services/hooks/useIsDeviceType";

import { PARCELS_TABLE_TABS } from "../../utils/consts";
import { tabNavListStyle } from "./style";
import * as Styled from "./style";

export function TabSwitcher({ activeTab, setActiveTab }) {
  const { id } = useParams();
  const { isTablet } = useIsDeviceType();
  const { t } = useTranslation("parcels-table");

  return (
    <Styled.Wrapper>
      <TabNavList style={tabNavListStyle}>
        <TabNavItem
          id={PARCELS_TABLE_TABS.TABLE}
          activeTab={activeTab}
          setActiveTab={setActiveTab}
          title={isTablet ? t(PARCELS_TABLE_TABS.TABLE) : t("Property Table")}
          href={ROUTE_PATHS.PARCELS_TABLE({
            id,
            tab: PARCELS_TABLE_TABS.TABLE,
          })}
        />

        <TabNavItem
          id={PARCELS_TABLE_TABS.MAP}
          activeTab={activeTab}
          setActiveTab={setActiveTab}
          title={isTablet ? t(PARCELS_TABLE_TABS.MAP) : t("Property Map")}
          href={ROUTE_PATHS.PARCELS_TABLE({
            id,
            tab: PARCELS_TABLE_TABS.MAP,
          })}
        />
      </TabNavList>
    </Styled.Wrapper>
  );
}
