import { useEffect, useState } from "react";
import { useQueryClient } from "@tanstack/react-query";

import { queryKeys } from "app/providers/react-query";
import API from "api/apiClient";

export function useNotification() {
  const [isLoading, setIsLoading] = useState(false);
  const [isError, setIsError] = useState(false);
  const [isNotificationListVisible, setIsNotificationListVisible] = useState(false);
  const [isNewNotification, setIsNewNotification] = useState(false);
  const [notifications, setNotifications] = useState([]);

  const queryClient = useQueryClient();

  useEffect(() => {
    if (isNewNotification) {
      queryClient.refetchQueries([queryKeys.projects]);
    }
  }, [isNewNotification, queryClient]);

  const onFetchNotifications = async () => {
    setIsError(false);
    setIsNotificationListVisible((prevState) => !prevState);
    setIsLoading(true);
    setIsNewNotification(false);

    try {
      const data = await API.getNotifications();
      setNotifications(data.data.customer_notifications);
    } catch {
      setIsError(true);
    } finally {
      setIsLoading(false);
    }
  };

  return {
    isLoading,
    isError,
    notifications,
    isNewNotification,
    isNotificationListVisible,
    onFetchNotifications,
    setIsNewNotification,
  };
}
