import styled from "styled-components/macro";

import { QUERIES } from "configs/styles/common";
import { TabNavItem } from "components/presenters/common/Tabs/ui/styles";

export const Wrapper = styled.div`
  width: 100%;

  & svg {
    height: 24px;
  }

  & ${TabNavItem} {
    min-width: 50px;
  }
`;

export const TabletTitle = styled.span`
  font-size: 11px;

  @media ${QUERIES.upToMobile} {
    display: none;
  }
`;

export const tabNavListStyle = {
  boxShadow: "none",
};
