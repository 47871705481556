import React from "react";
import styled, { keyframes } from "styled-components";

import { COLORS } from "configs/styles/common";

const CenterWrapper = styled.div`
  display: ${({ displaying }) => (displaying ? "inherit" : "none")};
  position: ${({ position }) => position};
  z-index: 9999;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
`;

const DotsWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  text-align: center;
  width: 80px;
  height: 100px;
  margin-left: auto;
  margin-right: auto;
`;

const jumping = (height) => keyframes`
  0% {
    transform: translateY(0);
    height: 10px;
  }

  35% {
    transform: translateY(0);
    background: ${COLORS.DARK.Main};
    border-radius: 50%;
    height: 10px;
  }

  40% {
    transform: translateY(0);
    height: ${height};
    background: ${COLORS.ACCENT};
    border-radius: 5px;
  }

  50% {
    transform: translateY(0);
    height:${height};
    background: ${COLORS.ACCENT};
    border-radius: 5px;
  }

	65% {
		transform: translateY(-15px);
    height: ${height};
    background: ${COLORS.ACCENT};
    border-radius: 5px;
	}

  70% {
    transform: translateY(-15px);
    height: 10px;
    background: ${COLORS.DARK.Main};
    border-radius: 50%;
  }

  80% {
    transform: translateY(-15px);
    height: 10px;
    background: ${COLORS.DARK.Main};
    border-radius: 50%;
  }

  95% {
    transform: translateY(0);
    height: 10px;
  }
`;

const Dot = styled.span`
  display: inline-block;
  width: 10px;
  height: 10px;
  margin-right: 3px;
  border-radius: 50%;
  background: ${COLORS.DARK.Main};
  animation-name: ${({ height }) => jumping(height)};
  animation-duration: 1.4s;
  animation-iteration-count: infinite;
  animation-timing-function: linear;

  &:nth-child(2) {
    animation-delay: 0.15s;
  }

  &:nth-child(3) {
    animation-delay: 0.3s;
  }

  &:nth-child(4) {
    animation-delay: 0.45s;
  }

  &:nth-child(5) {
    animation-delay: 0.6s;
  }
`;

export function Loader({ isLoading = false, position = "fixed" }) {
  return (
    <CenterWrapper data-testid="loader" displaying={isLoading} position={position}>
      <DotsWrapper>
        <Dot height="30px" />
        <Dot height="50px" />
        <Dot height="30px" />
        <Dot height="50px" />
        <Dot height="30px" />
      </DotsWrapper>
    </CenterWrapper>
  );
}
