import React, { forwardRef } from "react";
import ReactSelect from "react-select";
import WindowedSelect from "react-windowed-select";

import { customStyles } from "./style";
import { CustomSingleValue } from "./components/CustomSingleValue";
import { CustomOption } from "./components/CustomOption";

export const Select = forwardRef(function Select(props, ref) {
  const {
    testId = "",
    isLarge = false,
    isError = false,
    height = "35px",
    mobileHeight = "35px",
    minWidth = 0,
    specificStyle = {},
    isIcon = false,
    defaultMenuIsOpen = false,
    ...rest
  } = props;
  const Component = isLarge ? WindowedSelect : ReactSelect;

  return (
    <Component
      ref={ref}
      defaultMenuIsOpen={defaultMenuIsOpen}
      classNamePrefix={testId}
      menuPortalTarget={document.body}
      styles={{ ...customStyles, ...specificStyle }}
      isError={isError}
      height={height}
      mobileHeight={mobileHeight}
      minWidth={minWidth}
      isIcon={isIcon}
      components={isIcon && { Option: CustomOption, SingleValue: CustomSingleValue }}
      {...rest}
    />
  );
});
