import { useQuery, useQueryClient } from "@tanstack/react-query";
import { useEffect } from "react";

import { queryKeys } from "app/providers/react-query";

import { fetchGeoTIFF } from "../helpers";

export function useGeoRasterData({ url, isQueryEnabled }) {
  const queryClient = useQueryClient();

  const {
    data: georasterData,
    isFetching,
    isError,
  } = useQuery({
    queryKey: [queryKeys.geoTIFF, url],
    queryFn: ({ signal }) => fetchGeoTIFF(url, { signal }),
    enabled: isQueryEnabled,
  });

  useEffect(() => {
    if (!isQueryEnabled) {
      queryClient.cancelQueries({ queryKey: [queryKeys.geoTIFF, url] });
    }
  }, [isQueryEnabled, url, queryClient]);

  return { georasterData, isFetching, isError };
}
