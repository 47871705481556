const gradientColors = [
  "#6cd371",
  "#4CAF50",
  "#92A040",
  "#D3BE38",
  "#FFC300",
  "#FFB031",
  "#FFA031",
  "#FF7A31",
  "#FF5C39",
  "#E03B3B",
  "#C02832",
];

export function getColorForValue(value) {
  const clampedValue = Math.max(0, Math.min(100, value));

  const index = Math.floor(clampedValue / 10);

  return gradientColors[index];
}
