import styled from "styled-components/macro";

import { COLORS } from "configs/styles/common";
import Button from "components/presenters/common/Button/Button";

export const AddFootprint = styled(Button)`
  height: 36px;
  width: 37px;
  color: ${COLORS.DARK.Secondary};
  padding: 0;
  margin-left: 10px;
  box-shadow: 0 2px 2px 0 rgb(0 0 0 / 15%);
  flex-shrink: 0;

  & svg {
    height: 22px;
  }
`;
