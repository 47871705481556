import { toast } from "react-toastify";

export function showErrorToast(message, options = {}) {
  toast.error(message, options);
}

export function showSuccessToast(message, options = {}) {
  toast.success(message, options);
}

export function showInfoToast(message, options = {}) {
  toast.info(message, options);
}

export function showWarningToast(message, options = {}) {
  toast.warning(message, options);
}
