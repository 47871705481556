import styled from "styled-components/macro";

import { FONTS, WEIGHT } from "configs/styles/common";

export const modalTitleStyle = {
  fontSize: "20px",
  fontFamily: FONTS.Main,
  fontWeight: WEIGHT.regular,
};

export const ParcelMap = styled.div`
  width: 100%;
  height: 600px;
  position: relative;
`;
