export const fakeData = {
  items: [
    {
      originalParcelId: "C000CU74HJXR",
      parcelId: "1715700015170",
      address: "8936 Park Ln San Antonio, NY 70308",
      footprintsCount: 1,
      createdAt: new Date(),
      flags: ["wind", "tsunami"],
      risk: 80,
      children: [],
    },
    {
      originalParcelId: "C000CU74HJXR",
      parcelId: "1715700015314",
      address: "4970 Washington Blvd New York, TX 65115",
      footprintsCount: 1,
      createdAt: "2022-01-01",
      flags: ["fire", "wind"],
      risk: 0,
      children: [],
    },
    {
      originalParcelId: "C000CU74HJXR",
      parcelId: "1715700017791",
      address: "7516 Maple Blvd Los Angeles, PA 36238",
      footprintsCount: 2,
      createdAt: "2022-01-01",
      flags: ["tsunami", "flood"],
      risk: 60,
      children: [],
    },
    {
      originalParcelId: "C000CU74HJXR",
      parcelId: "100100000012",
      address: "7516 Maple Blvd Los Angeles, PA 36238",
      footprintsCount: 2,
      createdAt: "2022-01-01",
      flags: ["wind", "flood"],
      risk: 50,
      children: [],
    },
    {
      originalParcelId: "C000CU74HJXR",
      parcelId: "1715700015314",
      address: "7516 Maple Blvd Los Angeles, PA 36238",
      footprintsCount: 2,
      createdAt: "2022-01-01",
      flags: ["wind", "tsunami"],
      risk: 40,
      children: [],
    },
    {
      originalParcelId: "C000CU74HJXR",
      address: "4970 Washington Blvd New York, TX 65115",
      footprintsCount: 1,
      createdAt: "2022-01-01",
      flags: ["wind"],
      risk: 30,
      children: [],
    },
    {
      originalParcelId: "C000CU74HJXR",
      address: "4970 Washington Blvd New York, TX 65115",
      footprintsCount: 1,
      createdAt: "2022-01-01",
      flags: ["fire"],
      risk: 30,
      children: [],
    },
    {
      originalParcelId: "C000CU74HJXR",
      address: "7516 Maple Blvd Los Angeles, PA 36238",
      footprintsCount: 1,
      createdAt: "2022-01-01",
      flags: ["wind", "tsunami"],
      risk: 100,
      children: [],
    },
    {
      originalParcelId: "C000CU74HJXR",
      address: "7516 Maple Blvd Los Angeles, PA 36238",
      footprintsCount: 1,
      createdAt: "2022-01-01",
      flags: ["fire", "flood"],
      risk: 90,
      children: [],
    },
    {
      originalParcelId: "C000CU74HJXR",
      address: "7516 Maple Blvd Los Angeles, PA 36238",
      footprintsCount: 1,
      createdAt: "2022-01-01",
      flags: ["wind", "flood"],
      risk: 10,
      children: [],
    },
    {
      originalParcelId: "C000CU74HJXR",
      address: "7516 Maple Blvd Los Angeles, PA 36238",
      footprintsCount: 1,
      createdAt: "2022-01-01",
      flags: ["fire", "tsunami"],
      risk: 5,
      children: [],
    },
    {
      originalParcelId: "1289893",
      address: "7516 Maple Blvd Los Angeles, PA 36238",
      footprintsCount: 1,
      createdAt: "2022-01-01",
      flags: ["flood", "tsunami"],
      risk: 2,
      children: [],
    },
    {
      originalParcelId: "1289893",
      address: "7516 Maple Blvd Los Angeles, PA 36238",
      footprintsCount: 1,
      createdAt: "2022-01-01",
      flags: ["fire"],
      risk: 15,
      children: [],
    },
    {
      originalParcelId: "1289893",
      address: "7516 Maple Blvd Los Angeles, PA 36238",
      footprintsCount: 1,
      createdAt: "2022-01-01",
      flags: ["flood"],
      risk: 25,
      children: [],
    },
  ],
};

export const fakeChildren = [
  {
    originalParcelId: "children 1",
    address: "123 Main St, floor 1",
    footprintsCount: 1,
    createdAt: "2025-06-01",
    flags: ["wind", "rain"],
    risk: 70,
  },
  {
    originalParcelId: "children 2",
    address: "123 Main St, floor 2",
    footprintsCount: 1,
    createdAt: "2024-01-01",
    flags: ["wind", "rain"],
    risk: 50,
  },
  {
    originalParcelId: "children 3",
    address: "123 Main St, floor 3",
    footprintsCount: 1,
    createdAt: "2023-01-01",
    flags: ["wind", "rain"],
    risk: 10,
  },
];
