import * as PopoverRadix from "@radix-ui/react-popover";
import styled from "styled-components";

import { baseButtonStyle, COLORS } from "configs/styles/common";

export const arrowStyle = { fill: COLORS.LIGHT.White, filter: "drop-shadow(0 0 6px hsl(0deg 0% 0% / 10%))" };

export const closeSvgStyle = { height: 15, color: "inherit" };

export const Close = styled(PopoverRadix.Close)`
  ${baseButtonStyle};
  position: absolute;
  top: 2px;
  right: 5px;
  border-radius: 100%;
  width: 25px;
  height: 25px;
  color: ${COLORS.INPUT.AccentPlaceholder};
  transition: background-color 200ms ease-in;

  &:hover {
    background-color: ${COLORS.LIGHT.Background};
  }
`;
