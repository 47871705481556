import React from "react";
import { useTranslation } from "react-i18next";
import { ArrowLeftIcon } from "@heroicons/react/24/outline";

import { BackButton } from "components/presenters/common/BackButton";

import * as Styled from "./style";

export function BreadcrumbNavigation() {
  const { t } = useTranslation("parcels-table");

  return (
    <Styled.Breadcrumb>
      <BackButton>
        <ArrowLeftIcon />
        <span>{t("My Portfolios")}</span>
      </BackButton>
    </Styled.Breadcrumb>
  );
}
