import { useMemo } from "react";
import { format } from "date-fns";

export function useFormattedDate(date) {
  const formattedDate = useMemo(
    () => ({
      "en-US": date ? format(new Date(date), "dd/MM/yyyy") : "",
      ja: date ? format(new Date(date), "yyyy/MM/dd") : "",
    }),
    [date]
  );

  return formattedDate;
}
