import basicConfig from "../../basicConfig";

export function getBuildings(projectId, addressId) {
  const params = {
    project_id: projectId,
    address_id: addressId,
  };

  return basicConfig
    .createRequest(
      "/project/address/buildings",
      "GET",
      { "content-type": "application/json" },
      undefined,
      undefined,
      params
    )
    .then((res) => res.data.data);
}

export function updateParcelAddressRecord(address_id, data) {
  return basicConfig.createRequest(`/v1/parcels/${address_id}`, "PATCH", { "content-type": "application/json" }, data);
}

export function getParcelGeometryAndBuildingPoints(project_id, parcel_id) {
  const params = {
    project_id,
    parcel_id,
  };

  return basicConfig
    .createRequest(
      "/v1/parcels/get-boundary-and-points",
      "GET",
      {
        "content-type": "application/json",
      },
      undefined,
      undefined,
      params
    )
    .then((result) => result.data.data);
}

export function getFilteredAddressesDetail(
  project_id,
  page_num,
  page_size,
  sort_key = "address",
  sort_direction = "down",
  data
) {
  const params = {
    project_id,
    page_num,
    page_size,
    sort_key,
    sort_direction,
  };

  return basicConfig
    .createRequest(
      "/project/addresses-filtered-detail",
      "POST",
      { "content-type": "application/json" },
      data,
      undefined,
      params
    )
    .then((res) => res.data.data);
}

export function getAddresses(
  project_id,
  page_num,
  page_size,
  sort_key = "address",
  sort_direction = "down",
  country = "us"
) {
  const params = {
    project_id,
    page_num,
    page_size,
    sort_key,
    sort_direction,
    country,
  };

  return basicConfig
    .createRequest(
      "/project/addresses-filtered-detail",
      "GET",
      { "content-type": "application/json" },
      undefined,
      undefined,
      params
    )
    .then((res) => res.data.data);
}

export function deleteParcel({ addressId: addressId }) {
  return basicConfig
    .createRequest(`/v1/parcels/${addressId}/delete`, "DELETE", { "content-type": "application/json" })
    .then((res) => res.data.data);
}

export function getParcelIds(project_id, page_num, page_size, sort_key = "address", sort_direction = "down", data) {
  const params = {
    page_num,
    page_size,
    sort_key,
    sort_direction,
  };

  return basicConfig
    .createRequest(
      `/v1/project/${project_id}/get-parcel-ids`,
      "POST",
      { "content-type": "application/json" },
      data,
      undefined,
      params
    )
    .then((res) => res.data.data);
}

export function getParcelBuildingsAttributes(parcel_id) {
  return basicConfig
    .createRequest(`/v1/parcel/${parcel_id}/buildings`, "POST", { "content-type": "application/json" })
    .then((res) => res.data);
}
