import { useMutation, useQueryClient } from "@tanstack/react-query";
import { useTranslation } from "react-i18next";

import { showSuccessToast } from "services/utils/toast";

import { updateParcelAddressRecord } from "./addressAPI";

export function useUpdateAddress(queryKey) {
  const queryClient = useQueryClient();
  const { t } = useTranslation("table");

  const { mutate: updateAddress } = useMutation({
    mutationFn: ({ data, address_id }) => updateParcelAddressRecord(address_id, data),
    onSuccess: (data) => {
      queryClient.invalidateQueries({ queryKey: [queryKey] });

      data.data.data.is_favorite
        ? showSuccessToast(t("The address is successfully added to favorites."))
        : showSuccessToast(t("The address is successfully removed from favorites."));
    },
  });

  return { updateAddress };
}
