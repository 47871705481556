import React from "react";

import * as Styled from "./style";
import { CommentButton } from "./CommentButton";
import { ExportButton } from "./ExportButton";
import { ReportButton } from "./ReportButton";

export function ActionButtons() {
  return (
    <Styled.Wrapper>
      <CommentButton />
      <ExportButton />
      <ReportButton />
    </Styled.Wrapper>
  );
}
