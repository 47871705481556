import React from "react";
import { useTranslation } from "react-i18next";

import { Mixpanel } from "services/tracking/Mixpanel";
import { openTidioChat } from "services/utils/tidioChat";
import { ROUTE_PATHS } from "app/providers/router";
import { getProjectPageQueryParams } from "app/providers/router/helpers";

import { Logo } from "../Logo";
import { NavigationLink } from "../NavigationLink";
import { Notification } from "../../../../common/Notification";
import { LangSwitcher } from "../../../../common/LangSwitcher";
import { MyAccount } from "../MyAccount";
import * as Styled from "../../style";

export function PrivateLaptopHeader() {
  const { t } = useTranslation();

  return (
    <Styled.HeaderWrapper>
      <Styled.Header>
        <Styled.MainNavigation>
          <Logo />
          <NavigationLink to={ROUTE_PATHS.SINGLE_SEARCH}>{t("Search")}</NavigationLink>
          <NavigationLink to={{ pathname: ROUTE_PATHS.PROJECTS, search: getProjectPageQueryParams() }}>
            {t("Projects")}
          </NavigationLink>
          <NavigationLink to={ROUTE_PATHS.API_TEST}>{t("API")}</NavigationLink>
        </Styled.MainNavigation>

        <Styled.MainNavigation smallGap>
          <NavigationLink
            as="div"
            onClick={() => {
              Mixpanel.track("Click to support from Header on desktop");
              openTidioChat();
            }}
          >
            {t("Support")}
          </NavigationLink>
          <Notification />
          <MyAccount />
          <LangSwitcher />
        </Styled.MainNavigation>
      </Styled.Header>
    </Styled.HeaderWrapper>
  );
}
