import styled from "styled-components/macro";
import { css } from "styled-components";

import { BORDER_RADIUS, BOX_SHADOW, COLORS, FONTS, QUERIES, singleSearchMobileQuery } from "configs/styles/common";
import { MarkerOrangeSVG, MapLayerSVG } from "assets/images/shared";
import FullScreenImage from "assets/icons/full-screen.svg";

export const Empty = styled.div`
  width: 100%;
  height: 100%;
  background-color: ${COLORS.LIGHT.Background};
  display: grid;
  place-content: center;
  border-radius: ${BORDER_RADIUS.M};
  color: ${COLORS.INPUT.Error};
  text-align: center;
  padding: 20px;
  position: relative;
`;

export const TypeControl = styled.div`
  display: none;
  margin: 0;
  bottom: 10px !important;
  right: 10px !important;
  box-shadow: ${BOX_SHADOW.REGULAR};
  border-radius: 4px;
  border: none;
  align-items: center;
  background-color: ${COLORS.LIGHT.White};
  width: 235px;
  height: 35px !important;
  padding-left: 6px;

  &::after {
    position: absolute;
    content: "";
    width: 20px;
    height: 20px;
    background-image: url(${MapLayerSVG});
    background-size: 20px 20px;
    background-repeat: no-repeat;
    top: 7px;
    right: 8px;
  }
`;

export const CenterControl = styled(TypeControl)`
  bottom: 235px !important;
  display: grid;
  place-content: center;
  width: 35px;
  height: 35px;
  pointer-events: auto;
  cursor: pointer;
  background-image: url(${MarkerOrangeSVG});
  background-repeat: no-repeat;
  background-position: center;
  background-size: 14px;

  &::after {
    content: none;
  }

  @media ${singleSearchMobileQuery} {
    height: 33px;
    bottom: 285px !important;
    background-size: 12px;
  }

  @media ${QUERIES.upToMobile} {
    bottom: 265px !important;
  }
`;

export const DrawingControlWrapper = styled.div`
  position: absolute;
  height: 40px;
  width: 140px;
  box-shadow: 0 3px 6px 0 rgb(0 0 0 / 11%);
  border-radius: 4px;
  border: none;
  align-items: center;
  background-color: ${COLORS.LIGHT.White};
  top: 10px !important;
  left: 50% !important;
  transform: translateX(-50%);
  opacity: 0;
`;

export const MapWrapper = styled.div`
  height: 100%;
  width: 100%;
  position: relative;

  & ${TypeControl} {
    display: flex;
  }

  & button.gm-control-active,
  & .gm-style-mtc button,
  & button.gm-svpc,
  & .gmnoprint > div {
    border-radius: ${BORDER_RADIUS.XS} !important;
    background-color: #fff !important;
  }

  & .gmnoprint > div {
    width: 35px !important;
    height: 35px !important;
  }

  & button[aria-label="Zoom in"] img,
  & button[aria-label="Zoom out"] img {
    display: none;
  }

  & .gmnoprint .gm-control-active img {
    width: 14px !important;
    height: 14px !important;
  }

  & div.gmnoprint.gm-bundled-control.gm-bundled-control-on-bottom {
    right: ${({ moveMap }) => !moveMap && "35px !important"};
  }

  & button.gm-control-active.gm-fullscreen-control,
  & button.gm-svpc,
  & button.gm-control-active {
    width: 35px !important;
    height: 35px !important;
  }

  & button[aria-label="Zoom in"],
  & button[aria-label="Zoom out"] {
    box-shadow: ${BOX_SHADOW.REGULAR} !important;
    border-radius: 4px !important;
    width: 35px !important;
    height: 35px !important;

    &::before {
      position: absolute;
      content: "";
      background-color: ${COLORS.DARK.Main};
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      width: 12px;
      height: 2px;
    }
  }

  & button[aria-label="Zoom in"] {
    &::after {
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      width: 2px;
      height: 12px;
      position: absolute;
      content: "";
      background-color: ${COLORS.DARK.Main};
    }
  }

  & button.gm-control-active.gm-fullscreen-control img {
    display: none;
  }

  & button.gm-control-active.gm-fullscreen-control {
    background-image: url(${FullScreenImage}) !important;
    background-repeat: no-repeat !important;
    background-size: 18px !important;
    background-position: center !important;
  }

  & .gmnoprint button.gm-control-active.gm-compass-needle img,
  & .gmnoprint button.gm-control-active.gm-compass-turn img {
    width: 18px !important;
    height: 48px !important;
  }

  & .gm-iv-address {
    border-top-right-radius: ${BORDER_RADIUS.XS};
    border-bottom-right-radius: ${BORDER_RADIUS.XS};
  }

  & .gm-iv-container {
    display: none;
    border-top-left-radius: ${BORDER_RADIUS.XS};
    border-bottom-left-radius: ${BORDER_RADIUS.XS};
  }

  & .gm-style-mtc:not(:last-of-type) {
    margin-right: 5px;
  }

  ${({ isDrawingDisabled }) =>
    !isDrawingDisabled &&
    css`
      & ${DrawingControlWrapper} {
        display: none;
      }
    `}

  ${({ isDrawingDisabled }) =>
    isDrawingDisabled &&
    css`
      & div[role="menubar"] {
        background-color: ${COLORS.GREY.Secondary} !important;
        opacity: 0.7;
        cursor: default;
        pointer-events: none;
      }
    `}
  
  & button[aria-label="Stop drawing"],
  & button[aria-label="Add a marker"],
  & button[aria-label="Draw a line"],
  & button[aria-label="Draw a rectangle"],
  & button[aria-label="Draw a circle"],
  & button[aria-label="Draw a shape"] {
    padding: 8px !important;
    border-radius: ${BORDER_RADIUS.ICON} !important;
    box-shadow: ${BOX_SHADOW.REGULAR} !important;

    &:focus {
      outline-color: ${COLORS.DARK.Main} !important;
    }
  }

  & div[role="menubar"] {
    padding: 7px !important;
    background-color: ${COLORS.LIGHT.Background} !important;
    border-radius: ${BORDER_RADIUS.XS} !important;
    box-shadow: ${BOX_SHADOW.ICON_BUTTON} !important;
    display: flex !important;
    gap: 4px !important;
    z-index: 2;
  }
`;

export const SelectControl = styled.input`
  font-size: 14px;
  line-height: 30px;
  vertical-align: bottom;
  accent-color: ${COLORS.ACCENT};
  cursor: pointer;
  margin-right: 5px;
`;

export const MapTypeLabel = styled.label`
  margin-bottom: 0;
  font-family: ${FONTS.Main};
  font-size: 12px;
  font-size: ${({ lang }) => lang === "ja" && "10px"};
  font-weight: 400;
  color: ${COLORS.DARK.Secondary};
  cursor: pointer;
  margin-right: 11px;
`;

export const InfoWrapper = styled.div`
  background-color: ${COLORS.LIGHT.White};
  opacity: 0.95;
  border-radius: ${BORDER_RADIUS.XS};
  padding: 8px;
`;

export const InfoName = styled.p`
  font-size: ${({ fontSize }) => fontSize};
  color: #6a6a6a;
`;
