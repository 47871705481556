import { useEffect } from "react";

import API from "api/apiClient";

export function useSession({ isSignedIn, invalidateSession, updateUserState }) {
  useEffect(() => {
    async function checkSession() {
      if (isSignedIn) {
        try {
          const response = await API.getUser();
          if (!response) {
            invalidateSession();
          } else {
            await updateUserState(response);
          }
        } catch (error) {
          invalidateSession();
        }
      }
    }

    checkSession();
  }, [isSignedIn, invalidateSession, updateUserState]);
}
