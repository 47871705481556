import React, { useEffect, useState } from "react";
import styled from "styled-components/macro";
import { keyframes } from "styled-components";

import { COLORS } from "configs/styles/common";

export function SmallLoader({ size = 20, className = "", color = COLORS.DARK.Main }) {
  const [beats, setBeats] = useState(0);

  useEffect(() => {
    const intervalId = setInterval(() => {
      setBeats((beats) => (beats + 1) % 4);
    }, 300);

    return () => clearInterval(intervalId);
  }, []);

  return (
    <SmallLoaderContainer size={size} className={className} $color={color}>
      <Beat size={size} active={beats === 0} delay={0} />
      <Beat size={size} active={beats === 1} delay={0.15} />
      <Beat size={size} active={beats === 2} delay={0.3} />
      <Beat size={size} active={beats === 3} delay={0.45} />
    </SmallLoaderContainer>
  );
}

const beatAnimation = keyframes`
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.1);
  }
  100% {
    transform: scale(1);
  }
`;

const Beat = styled.div`
  width: ${(props) => props.size}px;
  height: ${(props) => props.size}px;
  border-radius: 50%;
  opacity: ${(props) => (props.active ? 1 : 0.3)};
  animation: ${beatAnimation} 0.6s infinite;
  animation-delay: ${(props) => props.delay}s;
`;

const SmallLoaderContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 3px;

  & ${Beat} {
    background-color: ${({ $color }) => $color};
  }
`;
