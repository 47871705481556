export const USA_STATES = [
  {
    value: "CA",
    label: "California",
  },
  {
    value: "CT",
    label: "Connecticut",
  },
  {
    value: "FL",
    label: "Florida",
  },
  {
    value: "GA",
    label: "Georgia",
  },
  {
    value: "MA",
    label: "Massachusetts",
  },
  {
    value: "NJ",
    label: "New Jersey",
  },
  {
    value: "NY",
    label: "New York",
  },
  {
    value: "PA",
    label: "Pennsylvania",
  },
  {
    value: "RI",
    label: "Rhode Island",
  },
  {
    value: "TX",
    label: "Texas",
  },
  {
    value: "TN",
    label: "Tennessee",
  },

  {
    value: "UT",
    label: "Utah",
  },
  {
    value: "WA",
    label: "Washington",
  },
  { value: "NH", label: "New Hampshire" },
];
