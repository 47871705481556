import React from "react";
import { useTranslation } from "react-i18next";

import { Tooltip } from "../../../../common/Tooltip";
import { VisuallyHidden } from "../../../../common/VisuallyHidden";
import * as Styled from "../style/style";

function DataCell({ isExpanded, onExpandedClick, id }) {
  const { t } = useTranslation("tooltips");

  return (
    <Tooltip content={<span>{isExpanded() ? t("Collapse") : t("Expand")}</span>}>
      <Styled.CollapseButton
        data-testid={`expand-row:${id}`}
        isExpanded={isExpanded()}
        {...{
          onClick: onExpandedClick(),
        }}
      >
        <VisuallyHidden>{isExpanded() ? t("Collapse") : t("Expand")}</VisuallyHidden>
      </Styled.CollapseButton>
    </Tooltip>
  );
}

export function generateDataColumn({ header }) {
  return {
    header,
    id: "data",
    cell: ({ row }) => (
      <DataCell isExpanded={row.getIsExpanded} onExpandedClick={row.getToggleExpandedHandler} id={row.id} />
    ),
    size: 100,
  };
}
