import React from "react";
import { useTranslation } from "react-i18next";

import { openTidioChat } from "services/utils/tidioChat";
import { useIsDeviceType } from "services/hooks/useIsDeviceType";

import * as Styled from "./styles";

export default function HeroSection() {
  const { t } = useTranslation("HomePage");
  const { isMobile } = useIsDeviceType();

  const videoName = isMobile ? "mobile-hero" : "hero";

  return (
    <Styled.HeroSection data-testid="home-page">
      <Styled.Video
        height={500}
        autoPlay
        loop
        muted
        playsInline
        preload="auto"
        role="none"
        poster={`${process.env.PUBLIC_URL}/assets/images/video-poster.webp`}
      >
        <source src={`${process.env.PUBLIC_URL}/assets/video/${videoName}.mp4`} type="video/mp4" />
      </Styled.Video>

      <Styled.Container>
        <Styled.Title>
          {t("Instant access to 3D")} <br />
          {t("property data powered by")} <br />
          {t("AI & Aerial Imagery")}
        </Styled.Title>
        <Styled.SubTitle>
          {t("Search 160 million US")} <br />
          {t("commercial and residential properties")} <br />
          {t("for the intelligence you need")}
        </Styled.SubTitle>
        <Styled.ContactButton onClick={openTidioChat}>{t("Let's Talk")}</Styled.ContactButton>
      </Styled.Container>
    </Styled.HeroSection>
  );
}
