import { useMemo, useState } from "react";

import { refreshToken } from "api/basicConfig";

export function useSSEParcelImage(addressId) {
  const [isParcelImageLoading, setIsParcelImageLoading] = useState(true);
  const [isParcelImageError, setIsParcelImageError] = useState(false);

  const sse = useMemo(() => {
    return new EventSource(`/api/v1/single-search-parcel-image/${addressId}`, {
      withCredentials: true,
    });
  }, [addressId]);

  sse.addEventListener("unauthorized", () => {
    refreshToken()
      .then((data) => {
        if (data.status !== 200) {
          sse.close();
        }
      })
      .catch(() => sse.close());
  });

  sse.addEventListener("parcel_image_fetch_in_progress", () => {});

  sse.addEventListener("parcel_image_success", () => {
    setIsParcelImageLoading(false);

    sse.close();
  });

  sse.addEventListener("parcel_image_failed", (e) => {
    if (e.data !== "null") {
      setIsParcelImageError(true);
    }
    setIsParcelImageLoading(false);
    sse.close();
  });

  sse.addEventListener("error", () => {
    setIsParcelImageError(true);
    setIsParcelImageLoading(false);
    sse.close();
  });

  return { isParcelImageError, isParcelImageLoading };
}
