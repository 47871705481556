import styled from "styled-components/macro";

import { BOX_SHADOW, COLORS, FONTS, WEIGHT, singleSearchMobileQuery } from "configs/styles/common";

const media = `@media ${singleSearchMobileQuery}`;

export const customStyles = {
  container: (provided, state) => ({
    ...provided,
    width: "100%",
    maxWidth: 550,
    minWidth: state.selectProps.minWidth,
  }),
  control: (provided, state) => ({
    ...provided,
    boxShadow: BOX_SHADOW.REGULAR,
    cursor: "pointer",
    border: "none",
    outline: state.isFocused ? `2px solid ${COLORS.DARK.Main}` : "none",
    borderRadius: 6,
    minHeight: state.selectProps.height,
    backgroundColor: state.selectProps.isError ? COLORS.INPUT.ErrorBg : COLORS.LIGHT.White,
    [media]: {
      minHeight: state.selectProps.mobileHeight,
    },
  }),
  option: (provided, state) => ({
    ...provided,
    fontSize: 16,
    cursor: "pointer",
    textTransform: "capitalize",
    color: COLORS.DARK.Secondary,
    backgroundColor: state.isFocused ? "#fff0b096" : "transparent",
    display: state.selectProps.isIcon && "flex",
    alignItems: state.selectProps.isIcon && "center",
    gap: state.selectProps.isIcon && "12px",
  }),
  placeholder: (provided, state) => ({
    ...provided,
    color: state.selectProps.isError ? COLORS.INPUT.AccentPlaceholder : COLORS.INPUT.Placeholder,
    textTransform: "capitalize",
    fontWeight: WEIGHT.regular,
    fontFamily: FONTS.Main,
    overflow: "hidden",
    textOverflow: "ellipsis",
    whiteSpace: "nowrap",
    fontSize: "1rem",
  }),
  indicatorSeparator: (provided, state) => ({
    ...provided,
    backgroundColor: state.selectProps.isError ? COLORS.INPUT.AccentPlaceholder : COLORS.INPUT.Placeholder,
    marginTop: "10px",
    marginBottom: "10px",
  }),
  dropdownIndicator: (provided, state) => ({
    ...provided,
    cursor: "pointer",
    svg: {
      path: {
        fill: state.isFocused ? COLORS.ACCENT : COLORS.DARK.Secondary,
      },
    },
    transform: state.isFocused && "rotate(180deg)",
  }),
  multiValue: (provided) => ({
    ...provided,
    maxWidth: 80,
  }),
  multiValueLabel: (provided) => ({
    ...provided,
    height: 25,
    paddingTop: 2,
    alignItems: "center",
  }),
  multiValueRemove: (provided) => ({
    ...provided,
    height: 25,
    paddingTop: 2,
    alignItems: "center",
  }),
  singleValue: (provided, state) => ({
    ...provided,
    fontWeight: 400,
    fontFamily: FONTS.Main,
    color: COLORS.DARK.Main,
    fontSize: "1rem",
    textTransform: "capitalize",
    display: state.selectProps.isIcon && "flex",
    alignItems: state.selectProps.isIcon && "center",
    gap: state.selectProps.isIcon && "12px",
  }),
  menu: (provided) => ({
    ...provided,
    marginTop: "5px",
    boxShadow: BOX_SHADOW.LIGHT,
    borderRadius: "6px",
    zIndex: 200,
  }),
  menuList: (provided) => ({
    ...provided,
    padding: "0",
    overflowX: "hidden",
    color: COLORS.DARK.Main,
    maxHeight: 200,
  }),
  menuPortal: (provided) => ({
    ...provided,
    zIndex: 9999,
  }),
};

export const IconWrapper = styled.div`
  background-color: var(--background-color);
  width: 24px;
  height: 24px;
  border-radius: 50%;
  display: grid;
  place-content: center;
`;
