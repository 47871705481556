import React from "react";

import { CustomGoogleMap } from "components/presenters/special/CustomGoogleMap";
import { MAP_CONTAINER_STYLE } from "services/constants/map/map-style";
import { MAP_TYPE } from "app/providers/business/MapProvider";

import * as Styled from "./style";

export function ModalMap() {
  return (
    <Styled.FootprintMap>
      <CustomGoogleMap
        id="footprint-map"
        typeControl={false}
        centerControl={false}
        streetViewControl={false}
        mapContainerStyle={MAP_CONTAINER_STYLE}
        position={{ lat: 26.200690555066657, lng: 127.69960588746635 }}
        mapType={MAP_TYPE.WHITE}
        zoom={12}
      />
    </Styled.FootprintMap>
  );
}
