import { GoogleMap, useJsApiLoader } from "@react-google-maps/api";
import React, { memo, useCallback, useState } from "react";
import { useTranslation } from "react-i18next";

import { MAP_TYPE, useMap } from "app/providers/business/MapProvider";
import { MapLoadError, MapTypeControl } from "components/presenters/special/CustomGoogleMap/components";
import { MAP_LOADER } from "services/constants/map/map-loader";
import { DEFAULT_MAP_CONTAINER_STYLE } from "services/constants/map/map-style";

import { WHITE_MAP_THEME } from "./services/Theme";
import * as Styled from "./style";

interface CustomGoogleMapProps {
  id: string;
  position: google.maps.LatLngLiteral;
  isLoading?: boolean;
  zoom: number;
  streetViewControl?: boolean;
  fullscreenControl?: boolean;
  centerControl?: boolean;
  zoomControl?: boolean;
  children?: React.ReactNode;
  outerSetMap?: (map: google.maps.Map) => void;
  bounds?: google.maps.LatLngBounds | null;
  mapType?: string;
  mapContainerClassName?: string;
  mapContainerStyle?: React.CSSProperties;
  extraOptions?: google.maps.MapOptions;
  typeControl?: boolean;
}

function CustomGoogleMap({
  id,
  position,
  isLoading,
  zoom,
  streetViewControl,
  fullscreenControl,
  centerControl,
  zoomControl,
  children,
  outerSetMap = undefined,
  bounds = null,
  mapType = MAP_TYPE.SATELLITE,
  mapContainerClassName = "map-container",
  mapContainerStyle = DEFAULT_MAP_CONTAINER_STYLE,
  extraOptions = {},
  typeControl = true,
}: CustomGoogleMapProps) {
  const { t } = useTranslation();
  const { activeMapType } = useMap();
  const { isLoaded, loadError } = useJsApiLoader(MAP_LOADER);

  const [map, setMap] = useState<google.maps.Map | null>(null);

  const onLoad = useCallback(
    function onLoad(mapInstance: google.maps.Map) {
      outerSetMap?.(mapInstance);
      setMap(mapInstance);
      const whiteMapType = new window.google.maps.StyledMapType(WHITE_MAP_THEME, { name: "White" });
      mapInstance.mapTypes.set("white", whiteMapType);
      mapInstance.setMapTypeId(!typeControl ? mapType : activeMapType);

      if (bounds) {
        mapInstance.fitBounds(bounds);
      }

      if (typeControl) {
        const typeControl = document.getElementById("map-type-control");
        mapInstance.controls[window.google.maps.ControlPosition.BOTTOM_RIGHT].push(typeControl as HTMLElement);
      }

      if (centerControl) {
        const centerControl = document.getElementById("map-center-control");
        mapInstance.controls[window.google.maps.ControlPosition.BOTTOM_RIGHT].push(centerControl as HTMLElement);
      }
    },
    [activeMapType, bounds, centerControl, typeControl, mapType, outerSetMap]
  );

  const onUnmount = useCallback(function callback() {
    setMap(null);
  }, []);

  const onCenterControlClick = useCallback(
    function () {
      position && map && map.panTo(position);
    },
    [map, position]
  );

  if (!isLoaded || isLoading) {
    return null;
  }

  if (loadError) {
    return <MapLoadError />;
  }

  if (!position.lat || !position.lng) {
    return (
      <Styled.Empty>
        <span>{t("We were unable to find provided location.")}</span>
      </Styled.Empty>
    );
  }

  return (
    <Styled.MapWrapper>
      <GoogleMap
        id={id}
        onLoad={onLoad}
        onUnmount={onUnmount}
        mapContainerClassName={mapContainerClassName}
        mapContainerStyle={mapContainerStyle}
        center={position}
        zoom={zoom}
        options={{
          mapTypeControl: false,
          cameraControl: false,
          zoomControl,
          streetViewControl,
          fullscreenControl,
          minZoom: 3,
          mapTypeControlOptions: {
            mapTypeIds: ["roadmap", "satellite", "white"],
            position: window.google.maps.ControlPosition.BOTTOM_RIGHT,
          },
          ...extraOptions,
        }}
      >
        {children}
        {centerControl && <Styled.CenterControl id="map-center-control" onClick={onCenterControlClick} />}
        <Styled.TypeControl id="map-type-control" style={{ display: !typeControl ? "none" : undefined }}>
          <MapTypeControl map={map} />
        </Styled.TypeControl>
      </GoogleMap>
    </Styled.MapWrapper>
  );
}

export default memo(CustomGoogleMap);
