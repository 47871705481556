export const queryKeys = {
  projects: "projects",
  projectsByAddress: "projects-by-address",
  projectDetails: "project-details",
  customer: "customer",
  avatar: "avatar",
  userBasic: "user-basic",
  mapMarkers: "map-markers",
  filterAddresses: "filter-addresses",
  addressBuildings: "address-buildings",
  projectStatistic: "project-statistic",
  buildingsMapBoundaries: "buildings-map-boundaries",
  parcelsBoundaries: "parcels-boundaries",
  governmentLayer: "government-layer",
  addressIds: "address-ids",
  createdProjectAddresses: "created-project-addresses",
  tiles: "tiles",
  comparisonProject: "comparison-project-details",
  parcelBuildingsAttributes: "parcel-buildings-attributes",
  parcelsTable: "parcels-table",
  parcelImage: "parcel-image",
  usDbGeoEntities: "us-db-geo-entities",
  usDbSearchEntities: "us-db-search-entities",
  usDbHexGroupGeoEntities: "us-db-hex-group-geo-entities",
  usDbFileList: "us-db-file-list",
  geoTIFF: "geotiff",
};
