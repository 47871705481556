import { useQuery } from "@tanstack/react-query";

import { queryKeys } from "app/providers/react-query";

import { getAddresses, getFilteredAddressesDetail } from "./addressAPI";

export function useFilteredAddresses(
  project_id,
  page_num,
  page_size,
  sort_key,
  sort_direction,
  data,
  isFiltering = false
) {
  const request = isFiltering ? getFilteredAddressesDetail : getAddresses;
  const {
    data: filteredAddresses = {},
    isLoading: isAddressesLoading,
    isError: isAddressesError,
  } = useQuery({
    queryFn: () => request(project_id, page_num, page_size, sort_key, sort_direction, data),
    queryKey: [queryKeys.filterAddresses, project_id, page_num, page_size, sort_key, sort_direction, data],
    enabled: !!project_id,
  });

  return { filteredAddresses, isAddressesLoading, isAddressesError };
}
