import { z } from "zod";

export const schemas = {
  us: z.object({
    state: z.object({ value: z.string().min(1, { message: "This field is required." }) }),
    city: z.object({ value: z.string().min(1, { message: "This field is required." }) }),
    address: z.string().min(1, { message: "This field is required." }),
  }),
  jp: z.object({
    prefecture: z.object({ value: z.string().min(1, { message: "This field is required." }) }),
    address: z.string().min(1, { message: "This field is required." }),
  }),
};

export const defaultInputValues = {
  us: {
    state: { value: "" },
    city: { value: "" },
    address: "",
  },
  jp: {
    prefecture: { value: "" },
    address: "",
  },
};
