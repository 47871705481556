import styled from "styled-components/macro";
import { css } from "styled-components";

import { centerStyle, COLORS, FONTS, WEIGHT } from "configs/styles/common";
import { COUNTRY } from "services/constants/location/countries";

export const dialogHeaderStyle = {
  marginRight: "auto",
};

export const dialogTitleStyle = {
  fontFamily: FONTS.Main,
  fontSize: "var(--20px)",
  color: COLORS.DARK.Secondary,
  textTransform: "capitalize",
};

export const Wrapper = styled.form`
  gap: 12px;
  width: 100%;

  & > button {
    margin-top: 20px;
    width: 150px;
    height: 35px;
    font-size: var(--14px);
    font-weight: ${WEIGHT.medium};
    margin-inline: auto;
  }

  ${({ $country }) =>
    $country === COUNTRY.us &&
    css`
      display: grid;
      grid-template-columns: 1fr 1fr;

      & > label,
      & > button {
        grid-column: span 2;
      }
    `}

  ${({ $country }) =>
    $country === COUNTRY.jp &&
    css`
      ${centerStyle};
      flex-direction: column;
    `}
`;

export const FootprintMap = styled.div`
  width: 100%;
  position: relative;
  height: 440px;
  grid-column: span 2;
`;
