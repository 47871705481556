import React from "react";
import { components } from "react-select";

import { IconWrapper } from "../style";

export function CustomSingleValue(props) {
  return (
    <components.SingleValue {...props}>
      <IconWrapper style={{ "--background-color": props.data.color }}>{props.data.icon}</IconWrapper>
      {props.data.label}
    </components.SingleValue>
  );
}
