import { useQuery } from "@tanstack/react-query";

import { queryKeys } from "app/providers/react-query";

import { getBuildings } from "./addressAPI";
import { getMainBuildingIndex } from "../helpers";

function transformData(data) {
  const mainBuildingIndex = getMainBuildingIndex(data.buildings_section);
  const mainBuilding = data.buildings_section?.[mainBuildingIndex];
  const mainRoof = data.roof_section?.[mainBuildingIndex];

  let sortedBuildings = [];
  let sortedRoofs = [];

  if (mainBuildingIndex !== -1) {
    const sortedBuildingsWithoutMainBuilding = data.buildings_section
      .filter((building, index) => index !== mainBuildingIndex)
      .sort((a, b) => a.building_id - b.building_id);

    sortedBuildings = [mainBuilding, ...sortedBuildingsWithoutMainBuilding];

    const sortedRoofsWithoutMainBuilding = data.roof_section
      .filter((roof, index) => index !== mainBuildingIndex)
      .sort((a, b) => a.building_id - b.building_id);

    sortedRoofs = [mainRoof, ...sortedRoofsWithoutMainBuilding];
  } else {
    sortedBuildings = data.buildings_section;
    sortedRoofs = data.roof_section;
  }

  return {
    address_summary: data.address_summary,
    buildings_section: sortedBuildings,
    roof_section: sortedRoofs,
    parcel_section: data.parcel_section,
  };
}

export function useAddressBuildings(projectId, addressId) {
  const {
    data: addressBuildings,
    isLoading: isAddressBuildingsLoading,
    isError: isAddressBuildingsError,
  } = useQuery({
    queryKey: [queryKeys.addressBuildings, projectId, addressId],
    queryFn: () => getBuildings(projectId, addressId),
    enabled: !!projectId && !!addressId && !addressId.startsWith("id"),
    select: transformData,
  });

  return { addressBuildings, isAddressBuildingsLoading, isAddressBuildingsError };
}
