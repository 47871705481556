import "./services/tracking/logger";

import React from "react";
import { hydrate, render } from "react-dom";

import { App } from "./app";

import "@fontsource-variable/work-sans";
import "@fontsource-variable/bitter";
import "configs/i18n/i18n.js";

const rootElement = document.querySelector("#root");
if (!rootElement) throw new Error("No root element found");

if (rootElement.hasChildNodes()) {
  hydrate(<App />, rootElement);
} else {
  render(<App />, rootElement);
}
