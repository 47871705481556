import React from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";

import { Tooltip } from "../../../../common/Tooltip";
import { VisuallyHidden } from "../../../../common/VisuallyHidden";
import * as Styled from "../style/style";

function ViewAddressCell({ addressId, projectId, href, index, projectType }) {
  const { t } = useTranslation("tooltips");

  return (
    <Tooltip content={<span>{t("Address view")}</span>}>
      <Link to={href({ projectId, addressId, buildingNum: 1, projectType })}>
        <Styled.ViewButton data-testid={`view-address_${index}`}>
          <VisuallyHidden>{t("Address view")}</VisuallyHidden>
        </Styled.ViewButton>
      </Link>
    </Tooltip>
  );
}

export function generateViewAddressColumn({ header, projectId, href, projectType }) {
  return {
    header,
    id: "report",
    cell: ({ row }) => (
      <ViewAddressCell
        addressId={row.original.address_id}
        projectId={projectId}
        href={href}
        index={row.index}
        projectType={projectType}
      />
    ),
  };
}
