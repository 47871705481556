import React, { memo } from "react";
import { Marker, MarkerProps } from "@react-google-maps/api";

import { MarkerSVG } from "assets/images/shared";

type CurrentLocationMarkerProps = {
  markerURL?: string;
} & MarkerProps;

function CurrentLocationMarker({ position, markerURL = MarkerSVG, ...rest }: CurrentLocationMarkerProps) {
  return <Marker position={position} icon={{ url: markerURL }} {...rest} />;
}

export default memo(CurrentLocationMarker);
