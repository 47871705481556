export const COUNTRY = {
  us: "us",
  jp: "jp",
};

export const COUNTRIES = [
  {
    label: "US",
    value: COUNTRY.us,
    subdivision: "state",
  },
  { label: "JP", value: COUNTRY.jp, subdivision: "prefecture" },
];
