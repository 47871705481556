import { init as initApm } from "@elastic/apm-rum";

import { env } from "configs/env";
import { GEOX_ENV } from "configs/env/consts";

const isApmActive = (env.GEOX_ENV === GEOX_ENV.PRODUCTION || env.GEOX_ENV === GEOX_ENV.STAGING) && !process.env.CI;

const apm = initApm({
  serviceName: "saas-frontend",
  serverUrl: env.APM_SERVER_URL,
  environment: env.GEOX_ENV,
  serviceVersion: "1.0.0",
  active: isApmActive,
});

export default apm;
