import { useInfiniteQuery } from "@tanstack/react-query";

import { queryKeys } from "app/providers/react-query";

import { parcelsApi } from "../lib/api";

export function useParcels({ id, limit }) {
  return useInfiniteQuery({
    queryKey: [queryKeys.parcelsTable, id, limit],
    queryFn: ({ pageParam = 1 }) =>
      parcelsApi.getParcels({
        id,
        limit,
        page: pageParam,
      }),
    getNextPageParam: (lastPage) =>
      lastPage?.metadata?.page < lastPage?.metadata?.maxPage ? lastPage?.metadata?.page + 1 : undefined,
  });
}
