export async function fetchGeoTIFF(url, { signal } = {}) {
  const response = await fetch(url, { signal });
  if (!response.ok) throw new Error("Error loading GeoTIFF");
  const arrayBuffer = await response.arrayBuffer();
  const { default: georaster } = await import("georaster");
  return georaster(arrayBuffer);
}

export function safeJSONStringify(value) {
  try {
    return JSON.stringify(value);
  } catch {
    return "error-boundaries";
  }
}
