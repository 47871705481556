import { useQuery } from "@tanstack/react-query";

import { queryKeys } from "app/providers/react-query";

import { getParcelGeometryAndBuildingPoints } from "./addressAPI";
import { getBuildingPoints } from "../helpers";

function transformData(data) {
  return {
    building_points: getBuildingPoints(data),
    parcel_boundary: data.parcel_boundary ?? [],
  };
}

export function useBuildingsMapBoundaries(project_id, address_id, isParcelImageReady) {
  const {
    data: buildingsMapBoundaries = {},
    isLoading,
    isError,
  } = useQuery({
    queryKey: [queryKeys.buildingsMapBoundaries, project_id, address_id, isParcelImageReady],
    queryFn: () => getParcelGeometryAndBuildingPoints(project_id, address_id),
    select: transformData,
    enabled: !!project_id && !!address_id && !!isParcelImageReady,
  });

  return { buildingsMapBoundaries, isLoading, isError };
}
