import React from "react";

import { ReactComponent as RainIcon } from "assets/icons/rain.svg";
import { ReactComponent as WindIcon } from "assets/icons/wind.svg";
import { ReactComponent as FireIcon } from "assets/icons/fire.svg";
import { ReactComponent as WavesIcon } from "assets/icons/waves.svg";

export const PARCELS_TABLE_TABS = {
  MAP: "map",
  TABLE: "table",
};

export const ATTRIBUTES = {
  number: "number",
  address: "address",
  externalId: "originalParcelId",
  image: "image",
  totalFootprint: "totalFootprints",
  flags: "flags",
  date: "date",
  risk: "risk",
};

export const PAGINATION_MODE = {
  BEFORE: "before",
  AFTER: "after",
};

export const PAGINATION_LIMIT = 20;

export const FLAGS = {
  flood: "flood",
  wind: "wind",
  fire: "fire",
  tsunami: "tsunami",
};

export const flagsIcons = {
  [FLAGS.flood]: {
    icon: <RainIcon />,
    color: "#45c4e8",
  },
  [FLAGS.wind]: {
    icon: <WindIcon />,
    color: "#54565a",
  },
  [FLAGS.fire]: {
    icon: <FireIcon />,
    color: "#ffa031",
  },
  [FLAGS.tsunami]: {
    icon: <WavesIcon />,
    color: "#4576e8",
  },
};

export const navigationBarHeight = {
  laptop: 70,
  tablet: 90,
};
