import React from "react";
import { BellIcon } from "@heroicons/react/24/outline";
import { BellIcon as BellSolidIcon } from "@heroicons/react/24/solid";

import { PopoverTrigger } from "components/presenters/common/Popover";
import { COLORS } from "configs/styles/common";

import { VisuallyHidden } from "../../VisuallyHidden";
import * as Styled from "../ui/style";

export const testId = "notification-icon";

export function NotificationButton({ visible, isNewNotification }) {
  return (
    <PopoverTrigger asChild>
      <Styled.BellWrapper isNewNotification={isNewNotification}>
        <VisuallyHidden>{isNewNotification ? "You have new notifications" : "No new notifications"}</VisuallyHidden>
        {isNewNotification ? (
          <BellSolidIcon style={{ color: COLORS.ACCENT }} data-testid={testId} />
        ) : visible ? (
          <BellSolidIcon data-testid={testId} />
        ) : (
          <BellIcon data-testid={testId} />
        )}
      </Styled.BellWrapper>
    </PopoverTrigger>
  );
}
