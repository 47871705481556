import mixpanel from "mixpanel-browser";

import { env } from "configs/env";

mixpanel.init(env.MIXPANEL_TOKEN);

const actions = {
  identify: (id) => {
    mixpanel.identify(id);
  },
  reset: () => {
    mixpanel.reset();
  },
  alias: (id) => {
    mixpanel.alias(id);
  },
  track: (name, props) => {
    mixpanel.track(name, props);
  },
  people: {
    set: (props) => {
      mixpanel.people.set(props);
    },
  },
};

export const Mixpanel = actions;
