export const NODE_ENV = {
  PRODUCTION: "production",
  TEST: "test",
  DEVELOPMENT: "development",
};

export const GEOX_ENV = {
  PRODUCTION: "production",
  STAGING: "staging",
  DEVELOPMENT: "development",
};
