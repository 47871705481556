import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";

import { SEO } from "components/seo";
import { Header } from "components/presenters/layouts/Header";
import { Container } from "configs/styles/common";

import { TabContent } from "../../components/presenters/common/Tabs";
import { TableTab } from "./components/TableTab";
import { MapTab } from "./components/MapTab";
import { PARCELS_TABLE_TABS } from "./utils/consts";
import { NavigationBar } from "./components/NavigationBar";
import { Spacer } from "../../components/presenters/layouts/Spacer";

export default function ParcelsTablePage() {
  const { tab } = useParams();
  const [activeTab, setActiveTab] = useState(tab);

  useEffect(() => {
    setActiveTab(tab);
  }, [tab]);

  return (
    <>
      <SEO title="Parcels Table" />
      <Header />
      <NavigationBar activeTab={activeTab} setActiveTab={setActiveTab} />

      <Spacer space={{ desktop: 30, tablet: 30, mobile: 20 }} />

      <Container>
        <TabContent activeTab={activeTab} id={PARCELS_TABLE_TABS.TABLE}>
          <TableTab />
        </TabContent>

        <TabContent activeTab={activeTab} id={PARCELS_TABLE_TABS.MAP}>
          <MapTab />
        </TabContent>
      </Container>
    </>
  );
}
