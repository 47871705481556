import React from "react";

import { CustomGoogleMap } from "components/presenters/special/CustomGoogleMap";

import * as Styled from "./style";

export function MapTab() {
  return (
    <Styled.MapWrapper>
      <CustomGoogleMap id="parcels-map" position={{ lat: 26.200690555066657, lng: 127.69960588746635 }} zoom={11} />
    </Styled.MapWrapper>
  );
}
