import React from "react";
import * as AlertDialogRadix from "@radix-ui/react-alert-dialog";

import * as Styled from "./style";

export const AlertDialog = AlertDialogRadix.Root;

export const AlertDialogTrigger = React.forwardRef((props, ref) => (
  <AlertDialogRadix.Trigger asChild ref={ref} {...props} />
));
AlertDialogTrigger.displayName = AlertDialogRadix.Trigger.displayName;

function AlertDialogPortal(props) {
  return <AlertDialogRadix.Portal {...props} />;
}
AlertDialogPortal.displayName = AlertDialogRadix.Portal.displayName;

const AlertDialogOverlay = React.forwardRef((props, ref) => {
  return <Styled.Overlay ref={ref} {...props} />;
});
AlertDialogOverlay.displayName = AlertDialogRadix.Overlay.displayName;

export const AlertDialogContent = React.forwardRef(({ maxWidth = 600, children, ...props }, ref) => {
  return (
    <AlertDialogPortal>
      <AlertDialogOverlay>
        <Styled.Content ref={ref} style={{ "--max-width": `${maxWidth}px` }} {...props}>
          {children}
        </Styled.Content>
      </AlertDialogOverlay>
    </AlertDialogPortal>
  );
});
AlertDialogContent.displayName = AlertDialogRadix.Content.displayName;

export function AlertDialogHeader(props) {
  return <Styled.Header {...props} />;
}
AlertDialogHeader.displayName = "AlertDialogHeader";

export function AlertDialogFooter(props) {
  return <Styled.Footer {...props} />;
}
AlertDialogFooter.displayName = "AlertDialogFooter";

export const AlertDialogTitle = React.forwardRef((props, ref) => {
  return <Styled.Title ref={ref} {...props} />;
});
AlertDialogTitle.displayName = AlertDialogRadix.Title.displayName;

export const AlertDialogDescription = React.forwardRef((props, ref) => {
  return <Styled.Description ref={ref} {...props} />;
});
AlertDialogDescription.displayName = AlertDialogRadix.Description.displayName;

export const AlertDialogAction = React.forwardRef((props, ref) => {
  return (
    <AlertDialogRadix.Action asChild>
      <Styled.ActionButton ref={ref} {...props} />
    </AlertDialogRadix.Action>
  );
});
AlertDialogAction.displayName = AlertDialogRadix.Action.displayName;

export const AlertDialogCancel = React.forwardRef((props, ref) => {
  return (
    <AlertDialogRadix.Cancel asChild>
      <Styled.CancelButton ref={ref} {...props} />
    </AlertDialogRadix.Cancel>
  );
});
AlertDialogCancel.displayName = AlertDialogRadix.Cancel.displayName;
