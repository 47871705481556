import { useMutation, useQueryClient } from "@tanstack/react-query";
import { useTranslation } from "react-i18next";

import { queryKeys } from "app/providers/react-query";
import { showSuccessToast } from "services/utils/toast";

import { deleteParcel } from "./addressAPI";

export function useDeleteParcel(projectId, pageIndex, pageSize, sortKey, sortOrder, filterData) {
  const queryClient = useQueryClient();
  const { t } = useTranslation("singleSearch");

  const { mutate: deleteParcelMutation } = useMutation({
    mutationFn: deleteParcel,
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: [queryKeys.filterAddresses, projectId, pageIndex, pageSize, sortKey, sortOrder, filterData],
      });
      showSuccessToast(t("The address is deleted successfully"));
    },
  });

  return { deleteParcelMutation };
}
