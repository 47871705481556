import { useState, useEffect } from "react";

let cachedModules = null;

export function useLeafletModules() {
  const [modules, setModules] = useState(cachedModules);

  useEffect(() => {
    if (!modules) {
      (async () => {
        try {
          const [reactLeaflet, geoRasterLayerModule, googleLayerModule] = await Promise.all([
            import("react-leaflet"),
            import("georaster-layer-for-leaflet"),
            import("react-leaflet-google-layer"),
            import("leaflet/dist/leaflet.css"),
          ]);

          cachedModules = {
            Map: reactLeaflet.Map,
            TileLayer: reactLeaflet.TileLayer,
            GeoJSON: reactLeaflet.GeoJSON,
            GeoRasterLayer: geoRasterLayerModule.default,
            GoogleLayer: googleLayerModule.default,
          };
          setModules(cachedModules);
        } catch (error) {
          console.error("Error while loading the modules", error);
        }
      })();
    }
  }, [modules]);

  return modules;
}
