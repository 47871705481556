import React from "react";

import { ReactComponent as TrashCanSVG } from "assets/icons/trash-can.svg";
import { DeleteModal } from "components/_modals/DeleteModal";

import { TrashWrapper } from "../style/style";

export function generateDeleteAddressColumn({ onConfirmDelete, projectId }) {
  return {
    header: () => (
      <TrashWrapper>
        <TrashCanSVG />
      </TrashWrapper>
    ),
    id: "delete",
    cell: ({ row }) => {
      return (
        <DeleteModal
          testId={`delete-row-${row.index}`}
          onConfirmDelete={() => onConfirmDelete({ projectId, addressId: row.original.address_id || row.original.id })}
        />
      );
    },
  };
}
