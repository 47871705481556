import { useQuery } from "@tanstack/react-query";

import { queryKeys } from "app/providers/react-query";

import { getParcelBuildingsAttributes } from "./addressAPI";
import { getMainBuildingIndex } from "../helpers";

function transformData(data) {
  const mainBuildingIndex = getMainBuildingIndex(data.data.buildings);
  const mainBuilding = data.data.buildings?.[mainBuildingIndex !== -1 ? mainBuildingIndex : 0];

  let sortedBuildings = [];

  if (mainBuildingIndex !== -1) {
    sortedBuildings = [mainBuilding, ...data.data.buildings.filter((building, index) => index !== mainBuildingIndex)];
  } else {
    sortedBuildings = data.data.buildings;
  }
  const transformedBuildings = sortedBuildings.map((building) => ({ ...data.data.parcel, ...building }));
  return transformedBuildings;
}

export function useParcelBuildingsAttributes(parcelId) {
  const {
    data = [],
    isLoading,
    isError,
  } = useQuery({
    queryKey: [queryKeys.parcelBuildingsAttributes, parcelId],
    queryFn: () => getParcelBuildingsAttributes(parcelId),
    select: transformData,
  });

  return { data, isLoading, isError };
}
