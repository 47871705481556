import { useContext } from "react";

import { ParcelsTableContext } from "./ParcelsTableProvider";

export function useParcelsTableContext() {
  const context = useContext(ParcelsTableContext);

  if (!context) {
    return new Error("Use Parcels Table context outside of Provider");
  }

  return context;
}
