import React from "react";
import * as DialogRadix from "@radix-ui/react-dialog";
import { useTranslation } from "react-i18next";

import { ReactComponent as CloseSVG } from "assets/icons/close_icon_dark.svg";

import * as Styled from "./style";
import { VisuallyHidden } from "../VisuallyHidden";

export function Dialog({ children, open = undefined, onOpenChange = undefined }) {
  return (
    <DialogRadix.Root open={open} onOpenChange={onOpenChange}>
      {children}
    </DialogRadix.Root>
  );
}

function DialogPortal(props) {
  return <DialogRadix.Portal {...props} />;
}
DialogPortal.displayName = DialogRadix.Portal.displayName;

const DialogOverlay = React.forwardRef((props, ref) => {
  return <Styled.Overlay ref={ref} {...props} />;
});
DialogOverlay.displayName = DialogRadix.Overlay.displayName;

export const DialogContent = React.forwardRef(({ maxWidth = 600, children, onClose, ...props }, ref) => {
  const { t } = useTranslation();

  return (
    <DialogPortal>
      <DialogOverlay>
        <Styled.Content ref={ref} style={{ "--max-width": `${maxWidth}px` }} {...props}>
          <DialogRadix.Close asChild>
            <Styled.Close onClick={() => onClose?.()}>
              <CloseSVG />
              <VisuallyHidden>{t("Close")}</VisuallyHidden>
            </Styled.Close>
          </DialogRadix.Close>
          {children}
        </Styled.Content>
      </DialogOverlay>
    </DialogPortal>
  );
});
DialogContent.displayName = DialogRadix.Content.displayName;

export function DialogHeader(props) {
  return <Styled.Header {...props} />;
}
DialogHeader.displayName = "DialogHeader";

export function DialogFooter(props) {
  return <Styled.Footer {...props} />;
}
DialogFooter.displayName = "DialogFooter";

export const DialogTitle = React.forwardRef((props, ref) => {
  return <Styled.Title ref={ref} {...props} />;
});
DialogTitle.displayName = DialogRadix.Title.displayName;

export const DialogDescription = React.forwardRef((props, ref) => {
  return <Styled.Description ref={ref} {...props} />;
});
DialogDescription.displayName = DialogRadix.Description.displayName;

export const DialogActionButton = React.forwardRef((props, ref) => {
  return (
    <DialogRadix.Close asChild>
      <Styled.ActionButton ref={ref} {...props} />
    </DialogRadix.Close>
  );
});
DialogActionButton.displayName = "DialogActionButton";

export const DialogTrigger = React.forwardRef(({ ...props }, ref) => {
  return <DialogRadix.Trigger ref={ref} asChild {...props} />;
});
DialogTrigger.displayName = DialogRadix.Trigger.displayName;
