import React from "react";
import { useTranslation } from "react-i18next";

import { useFormattedDate } from "services/hooks/useFormattedDate";

export function DateCell({ date }) {
  const { i18n } = useTranslation();
  const formattedDate = useFormattedDate(date);

  return <span>{formattedDate[i18n.language]}</span>;
}
