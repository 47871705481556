import React, { useState } from "react";

import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from "components/presenters/common/Dialog";
import { Loader } from "components/presenters/common/Loader";
import { MapWithGeoTIFF } from "components/presenters/special/MapWithGeoTiff";
import { MapLoadError } from "components/presenters/special/CustomGoogleMap/components";

import { modalTitleStyle, ParcelMap } from "./style";
import { useParcelImage } from "../../hooks/useParcelImage";

export function ParcelImageModal({ imageModalTrigger, parcelId }) {
  const [isOpen, setIsOpen] = useState(false);

  const { data, isFetching, isError } = useParcelImage({ parcelId, enabled: isOpen && !!parcelId });

  const boundaries = data?.parcelGeometry;

  return (
    <Dialog open={isOpen} onOpenChange={setIsOpen}>
      <DialogTrigger>{imageModalTrigger}</DialogTrigger>

      <DialogContent aria-describedby={undefined} maxWidth={1000}>
        <DialogHeader>
          <DialogTitle style={modalTitleStyle} aria-describedby={undefined} />
          <DialogDescription aria-describedby={undefined} />
        </DialogHeader>

        <ParcelMap>
          <Loader isLoading={isFetching} position="absolute" />
          {data?.url && (
            <MapWithGeoTIFF
              url={data.url}
              shouldFetchGeoTIFF={isOpen && !!data.url}
              centerLat={data.imageCenterLat}
              centerLng={data.imageCenterLng}
              boundaries={boundaries}
            />
          )}
          {isError && <MapLoadError />}
        </ParcelMap>
      </DialogContent>
    </Dialog>
  );
}
