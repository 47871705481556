import React from "react";
import { DocumentTextIcon } from "@heroicons/react/24/outline";

import * as Styled from "./style";

export function ReportButton() {
  return (
    <Styled.ActionButton>
      <DocumentTextIcon />
    </Styled.ActionButton>
  );
}
