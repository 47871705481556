import React, { forwardRef } from "react";
import { useHistory } from "react-router-dom";
import { ArrowLeftIcon } from "@heroicons/react/24/outline";

import { ROUTE_PATHS } from "app/providers/router";
import { getProjectPageQueryParams } from "app/providers/router/helpers";

import Button from "../Button/Button";

export const BackButton = forwardRef(function BackButton(
  { children = <ArrowLeftIcon style={{ height: "24px" }} />, variant = "ghost", onGoBack = null, ...delegated },
  ref
) {
  const history = useHistory();

  function onBackClick() {
    if (onGoBack) {
      onGoBack();
      return;
    }

    if (!history.length) {
      history.push({ pathname: ROUTE_PATHS.PROJECTS, search: getProjectPageQueryParams() });
    }
    history.goBack();
  }

  return (
    <Button onClick={onBackClick} variant={variant} ref={ref} {...delegated} data-testid="go-back">
      {children}
    </Button>
  );
});
