import { COLORS } from "configs/styles/common";

export const mapContainerStyle = {
  height: "100%",
  width: "100%",
};

export function getGeoJSONStyle(color = COLORS.ACCENT) {
  return {
    color,
    weight: 4,
    fillColor: "transparent",
    fillOpacity: 1,
  };
}
