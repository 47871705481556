import styled from "styled-components/macro";

import { CollapseBoldSVG, ViewSVG } from "assets/images/shared";
import { baseButtonStyle, BORDER_RADIUS, BOX_SHADOW, COLORS } from "configs/styles/common";

export const CollapseButton = styled.button`
  ${baseButtonStyle};
  width: 32px;
  height: 28px;
  background-color: transparent;
  position: relative;
  box-shadow: ${BOX_SHADOW.ICON_BUTTON};
  border-radius: ${BORDER_RADIUS.ICON};
  background-image: url(${CollapseBoldSVG});
  background-position: center;
  background-repeat: no-repeat;
  background-size: 50%;
  transform: rotate(180deg);
  transform: ${({ isExpanded }) => isExpanded && "rotate(0deg)"};

  &::after {
    position: absolute;
    content: "";

    --tap-increment: -8px;

    inset: var(--tap-increment);
  }
`;

export const ViewButton = styled(CollapseButton)`
  background-image: url(${ViewSVG});
`;

export const AddressWrapper = styled.div`
  display: flex;
  gap: 5px;
`;

export const SortingButton = styled.button`
  ${baseButtonStyle};
  width: 30px;
  height: 24px;
  background-color: ${COLORS.LIGHT.Slate};
  border-radius: 5px;

  & svg {
    height: 10px;
    width: 11px;
  }
`;

export const TrashWrapper = styled.div`
  min-width: 35px;

  & svg {
    height: 20px;
  }
`;

export const DeleteWrapper = styled.button`
  ${baseButtonStyle};
  position: relative;

  & svg {
    height: 16px;
  }

  &::after {
    position: absolute;
    content: "";

    --tap-increment: -8px;

    inset: var(--tap-increment);
  }
`;
