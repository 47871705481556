import React from "react";
import { useTranslation } from "react-i18next";

import { Select } from "components/inputs/Select";

import { parcelsSpecificStyle } from "../../utils/style";
import { useParcelsTableContext } from "../../store/useParcelsTableContext";
import { useFlagsOptions } from "../../hooks/useFlagsOptions";

export function FlagSelect() {
  const { selectedData, setSelectedData } = useParcelsTableContext();
  const { t } = useTranslation("parcels-table");
  const FLAGS_OPTIONS = useFlagsOptions();

  return (
    <Select
      options={FLAGS_OPTIONS}
      value={selectedData}
      onChange={setSelectedData}
      placeholder={t("data")}
      minWidth={164}
      specificStyle={parcelsSpecificStyle}
      isIcon
    />
  );
}
