import React from "react";
import { useTranslation } from "react-i18next";

import { Empty } from "components/presenters/special/CustomGoogleMap/style";

export function MapLoadError() {
  const { t } = useTranslation();

  return (
    <Empty>
      <span>{t("Map cannot be loaded right now, sorry.")}</span>
    </Empty>
  );
}
