import { XMarkIcon } from "@heroicons/react/24/outline";
import React from "react";
import styled from "styled-components";
import { useTranslation } from "react-i18next";

import { baseButtonStyle } from "configs/styles/common";

import { VisuallyHidden } from "../../presenters/common/VisuallyHidden";

const DeleteWrapper = styled.button`
  ${baseButtonStyle};
  position: relative;

  & svg {
    height: 16px;
  }

  &::after {
    position: absolute;
    content: "";

    --tap-increment: -8px;

    inset: var(--tap-increment);
  }
`;

export const DeleteTrigger = React.forwardRef((props, ref) => {
  const { t } = useTranslation("modals");

  return (
    <DeleteWrapper data-testid={props.testId} ref={ref} {...props}>
      <VisuallyHidden>{t("Delete")}</VisuallyHidden>
      <XMarkIcon />
    </DeleteWrapper>
  );
});

DeleteTrigger.displayName = "DeleteTrigger";
