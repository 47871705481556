import { useQuery } from "@tanstack/react-query";

import { queryKeys } from "app/providers/react-query";

import { getParcelIds } from "./addressAPI";

export function useAddressIds(projectId) {
  const { data: addressesNavigation = {}, isAddressIdsLoading } = useQuery({
    queryKey: [queryKeys.addressIds, projectId],
    queryFn: () => getParcelIds(projectId),
  });

  return { addressesNavigation, isAddressIdsLoading };
}
