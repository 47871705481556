import { useEffect } from "react";

export function useAddGeoRasterLayer({ mapInstance, georasterData, modules }) {
  useEffect(() => {
    if (!mapInstance || !georasterData || !modules?.GeoRasterLayer) return;

    const layer = new modules.GeoRasterLayer({
      georaster: georasterData,
      resolution: 256,
    });

    layer.addTo(mapInstance);

    return () => {
      mapInstance.removeLayer(layer);
    };
  }, [georasterData, modules, mapInstance]);
}
