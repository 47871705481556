import React, { useMemo, memo, useCallback, useState } from "react";
import { GoogleMap, Polygon, useJsApiLoader } from "@react-google-maps/api";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";

import { MAP_TYPE } from "app/providers/business/MapProvider";
import { useBuildingsMapBoundaries } from "api/react-query/addressAPI";
import { useSSEParcelImage } from "services/hooks/business/useSSEParcelImage";
import { MAP_CONTAINER_STYLE } from "services/constants/map/map-style";
import { POLYGON_OPTIONS } from "services/constants/map/polygon-style-options";
import { MAP_LOADER } from "services/constants/map/map-loader";

import { BuildingMarkers } from "./BuildingMarkers";
import { MapWrapper } from "../../style";
import * as Styled from "./style";

function BuildingsMap({ projectId, addressId, addressData, href, fullSize = false, isPdf = false }) {
  const [polygonsPositions, setPolygonPosition] = useState([]);
  const history = useHistory();

  const { t } = useTranslation();
  const { isParcelImageLoading, isParcelImageError } = useSSEParcelImage(addressId);
  const isParcelImageReady = !isParcelImageLoading && !isParcelImageError;
  const { buildingsMapBoundaries, isLoading, isError } = useBuildingsMapBoundaries(
    projectId,
    addressId,
    isParcelImageReady
  );

  const { isLoaded, loadError } = useJsApiLoader(MAP_LOADER);

  const mapPosition = useMemo(
    () => ({ lat: addressData?.address_summary?.lat, lng: addressData?.address_summary?.lon }),
    [addressData?.address_summary]
  );

  const onLoad = useCallback(
    function onLoad(mapInstance) {
      mapInstance.setMapTypeId(MAP_TYPE.SATELLITE);

      const bounds = new window.google.maps.LatLngBounds();

      const nextPolygonPosition = buildingsMapBoundaries?.parcel_boundary.map((parcel) => {
        return parcel.map((polygon) => {
          bounds.extend({ lat: polygon[0], lng: polygon[1] });
          return { lat: polygon[0], lng: polygon[1] };
        });
      });

      setPolygonPosition(nextPolygonPosition);

      mapInstance.fitBounds(bounds);
    },
    [buildingsMapBoundaries]
  );

  const onMarkerClick = useCallback(
    function (index) {
      if (!href) return;
      history.push(href({ projectId, addressId, buildingNum: index + 1 }));
    },
    [history, href, addressId, projectId]
  );

  if (loadError || isError) {
    return (
      <Styled.ErrorWrapper>
        <span>{t("Map cannot be loaded right now, sorry.")}</span>
      </Styled.ErrorWrapper>
    );
  }

  if (isLoading) {
    return (
      <Styled.LoadingWrapper>
        <span>{t("Map is loading...")}</span>
      </Styled.LoadingWrapper>
    );
  }

  if (!mapPosition.lat || !mapPosition.lng) {
    return (
      <Styled.LoadingWrapper>
        <span>{t("We were unable to find provided location.")}</span>
      </Styled.LoadingWrapper>
    );
  }

  if (!isLoaded) {
    return null;
  }

  const mainBuildingID = addressData.buildings_section[0].building_id;

  const mainBuildingPoint =
    buildingsMapBoundaries.building_points?.find((item) => String(item[0]) === String(mainBuildingID)) ??
    buildingsMapBoundaries.building_points[0];

  const sortedPoints = [
    mainBuildingPoint,
    ...buildingsMapBoundaries.building_points.filter((point) => point !== mainBuildingPoint),
  ];

  const MAP_OPTIONS = {
    mapTypeControl: false,
    zoomControl: true,
    streetViewControl: false,
    rotateControl: true,
    fullscreenControl: true,
    minZoom: 3,
    rotateControlOptions: {
      position: window?.google?.maps?.ControlPosition?.LEFT_BOTTOM,
    },
    zoomControlOptions: {
      position: window?.google?.maps?.ControlPosition?.RIGHT_BOTTOM,
    },
  };

  const PDF_MAP_OPTIONS = {
    mapTypeControl: false,
    zoomControl: false,
    streetViewControl: false,
    rotateControl: false,
    fullscreenControl: false,
  };

  return (
    <MapWrapper fullSize={fullSize}>
      <GoogleMap
        id="buildings-map"
        onLoad={onLoad}
        mapContainerStyle={MAP_CONTAINER_STYLE}
        center={mapPosition}
        zoom={19}
        options={isPdf ? PDF_MAP_OPTIONS : MAP_OPTIONS}
      >
        {!!sortedPoints.length && (
          <BuildingMarkers onMarkerClick={onMarkerClick} buildingPoints={sortedPoints} isPdf={isPdf} />
        )}

        {!!polygonsPositions.length &&
          polygonsPositions.map((position, index) => {
            return <Polygon key={index} paths={position} options={POLYGON_OPTIONS} />;
          })}

        {!!addressData.buildings_section.length && (
          <Styled.LongLat data-testid="lat-lng" isPdf={isPdf}>
            <span>{addressData.address_summary.lat},</span> <span>{addressData.address_summary.lon}</span>
          </Styled.LongLat>
        )}
      </GoogleMap>
    </MapWrapper>
  );
}

export default memo(BuildingsMap);
