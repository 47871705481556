import { COLORS } from "../../../configs/styles/common";

export const POLYGON_OPTIONS = {
  fillColor: "transparent",
  strokeColor: COLORS.ACCENT,
  fillOpacity: 0.7,
  strokeOpacity: 1,
  strokeWeight: 2,
  clickable: true,
  draggable: false,
  editable: false,
  geodesic: false,
  zIndex: 1,
};
export const GEODESIC_POLYGON_OPTIONS = {
  fillColor: "transparent",
  strokeColor: COLORS.ACCENT,
  fillOpacity: 0.7,
  strokeOpacity: 1,
  strokeWeight: 2,
  clickable: true,
  draggable: false,
  editable: false,
  geodesic: true,
  zIndex: 100,
};
