import { useTranslation } from "react-i18next";
import { useMemo } from "react";

import { ATTRIBUTES } from "../utils/consts";

export function useAttributeOptions() {
  const { t } = useTranslation("parcels-table");

  const attributeOptions = useMemo(
    () => [
      { label: t(ATTRIBUTES.number), value: ATTRIBUTES.number },
      { label: t(ATTRIBUTES.address), value: ATTRIBUTES.address },
      { label: t(ATTRIBUTES.totalFootprint), value: ATTRIBUTES.totalFootprint },
      { label: t(ATTRIBUTES.flags), value: ATTRIBUTES.flags },
      { label: t(ATTRIBUTES.date), value: ATTRIBUTES.date },
      { label: t(ATTRIBUTES.risk), value: ATTRIBUTES.risk },
    ],
    [t]
  );

  return attributeOptions;
}
