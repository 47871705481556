import React, { forwardRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { EyeIcon, EyeSlashIcon, XMarkIcon } from "@heroicons/react/24/outline";

import { Tooltip } from "../../presenters/common/Tooltip";
import { VisuallyHidden } from "../../presenters/common/VisuallyHidden";
import * as Styled from "./style";

const baseStyle = { height: 35 };

export const CustomInput = forwardRef(function CustomInput(props, ref) {
  const {
    type = "text",
    icon = null,
    clearValue = null,
    error = "",
    style = baseStyle,
    errorTestId = "",
    ...delegated
  } = props;

  const [showPassword, setShowPassword] = useState(false);

  function handleTogglePassword() {
    setShowPassword((prevState) => !prevState);
  }

  const { i18n } = useTranslation();

  return (
    <Styled.Wrapper>
      {icon && <Styled.IconWrapper>{icon}</Styled.IconWrapper>}

      <Styled.TextInput
        ref={ref}
        type={showPassword ? "text" : type}
        isError={error}
        style={{ "--padding-inline": icon ? "35px 30px" : "15px", ...style }}
        lang={i18n.language}
        {...delegated}
      />

      {clearValue && !error && (
        <Styled.DeleteWrapper onClick={clearValue} type="button" data-testid="clear-button">
          <XMarkIcon style={{ height: 16, color: "inherit" }} />
        </Styled.DeleteWrapper>
      )}

      {error && (
        <Tooltip id="error" content={<span data-testid={errorTestId}>{error}</span>}>
          <Styled.ErrorWrapper data-testid={`error-sign-${errorTestId}`} type="button" aria-describedby="error" />
        </Tooltip>
      )}

      {type === "password" && !error && (
        <Styled.DeleteWrapper onClick={handleTogglePassword} type="button" data-testid="toggle-button">
          <VisuallyHidden>{type === "password" ? "Show password" : "Hide password"}</VisuallyHidden>
          {showPassword ? (
            <EyeIcon style={{ height: 18, color: "inherit" }} />
          ) : (
            <EyeSlashIcon style={{ height: 18, color: "inherit" }} />
          )}
        </Styled.DeleteWrapper>
      )}
    </Styled.Wrapper>
  );
});
