import React, { Fragment } from "react";
import { flexRender } from "@tanstack/react-table";
import { useVirtualizer } from "@tanstack/react-virtual";

import { COLORS } from "configs/styles/common";

import * as Styled from "../style";

export function TableBody({ table, tableContainerRef, lastRowRef }) {
  const rows = table.getRowModel().rows;

  const rowVirtualizer = useVirtualizer({
    count: rows.length,
    getScrollElement: () => tableContainerRef.current,
    estimateSize: () => 55,
    overscan: 10,
  });

  const tBodyHeight = `${rowVirtualizer.getTotalSize()}px`;
  const virtualItems = rowVirtualizer.getVirtualItems();

  return (
    <Styled.GridTBody
      style={{
        "--tbody-height": tBodyHeight,
      }}
    >
      {virtualItems.map((virtualRow) => {
        const row = rows[virtualRow.index];
        if (!row) return null;

        const isLastRow = virtualRow.index === rows.length - 1;

        return (
          <Fragment key={row.id}>
            <Styled.GridTRow
              style={{
                "--background-color": row.depth === 0 ? COLORS.LIGHT.White : COLORS.LIGHT.Accent,
                "--transform": `translateY(${virtualRow.start}px)`,
              }}
              ref={isLastRow ? lastRowRef : null}
            >
              {row.getVisibleCells().map((cell) => {
                const justifyContent = cell.column.columnDef.align;
                const minWidth = cell.column.getSize();
                const cellContext = cell.getContext();

                return (
                  <Styled.GridTData
                    key={cell.id}
                    style={{
                      "--justify-content": justifyContent,
                      "--min-width": minWidth,
                    }}
                  >
                    {flexRender(cell.column.columnDef.cell, cellContext)}
                  </Styled.GridTData>
                );
              })}
            </Styled.GridTRow>
          </Fragment>
        );
      })}
    </Styled.GridTBody>
  );
}
