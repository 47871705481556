import React from "react";
import { CameraIcon, MagnifyingGlassPlusIcon } from "@heroicons/react/24/outline";

import { ParcelImageModal } from "../../ParcelImageModal";
import * as Styled from "../../../utils/style";

export function ImageCell({ row }) {
  return (
    <ParcelImageModal
      parcelId={row.original.parcelId}
      imageModalTrigger={
        <Styled.ImageCell>{row.original?.children ? <CameraIcon /> : <MagnifyingGlassPlusIcon />}</Styled.ImageCell>
      }
    />
  );
}
