import { useQuery, useQueryClient } from "@tanstack/react-query";
import { useEffect } from "react";

import { queryKeys } from "app/providers/react-query";

import { parcelsApi } from "../lib/api";

export function useParcelImage({ parcelId, enabled }) {
  const queryClient = useQueryClient();

  const { data, isFetching, isError } = useQuery({
    queryKey: [queryKeys.parcelImage, parcelId],
    queryFn: ({ signal }) => parcelsApi.getParcelImage({ parcelId, signal }),
    enabled,
  });

  useEffect(() => {
    if (!enabled) {
      queryClient.cancelQueries({ queryKey: [queryKeys.parcelImage, parcelId] });
    }
  }, [enabled, queryClient, parcelId]);

  return { data, isFetching, isError };
}
