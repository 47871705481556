import { useState } from "react";

import API from "api/apiClient";

export function usePopup() {
  const [isPopupShown, setIsPopupShown] = useState(false);
  const [popupData, setPopupData] = useState({});

  function onPopupClose() {
    setIsPopupShown(false);
    API.markPopupFinished(popupData?.id).catch((error) => {
      console.error("Failed to mark popup as finished:", error);
    });
  }

  return {
    isPopupShown,
    setIsPopupShown,
    popupData,
    setPopupData,
    onPopupClose,
  };
}
