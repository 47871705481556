import React from "react";
import { useTranslation } from "react-i18next";

import {
  AlertDialog,
  AlertDialogAction,
  AlertDialogCancel,
  AlertDialogContent,
  AlertDialogDescription,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogTitle,
  AlertDialogTrigger,
} from "../../presenters/common/AlertDialog";
import { DeleteTrigger } from "./DeleteTrigger";

export function DeleteModal({ onConfirmDelete, testId = "", deleteTrigger = <DeleteTrigger testId={testId} /> }) {
  const { t } = useTranslation("modals");

  return (
    <AlertDialog>
      <AlertDialogTrigger>{deleteTrigger}</AlertDialogTrigger>
      <AlertDialogContent>
        <AlertDialogHeader>
          <AlertDialogTitle>{t("Are you absolutely sure?")}</AlertDialogTitle>
        </AlertDialogHeader>
        <AlertDialogDescription>
          {t("This action cannot be undone. This will permanently delete your data")}
        </AlertDialogDescription>
        <AlertDialogFooter>
          <AlertDialogCancel>{t("Cancel")}</AlertDialogCancel>
          <AlertDialogAction onClick={onConfirmDelete}>{t("Confirm")}</AlertDialogAction>
        </AlertDialogFooter>
      </AlertDialogContent>
    </AlertDialog>
  );
}
