import { useTranslation } from "react-i18next";
import { useMemo } from "react";

import { FLAGS, flagsIcons } from "../utils/consts";

export function useFlagsOptions() {
  const { t } = useTranslation("parcels-table");

  const flagsOptions = useMemo(
    () => [
      {
        label: t(FLAGS.wind),
        value: FLAGS.wind,
        icon: flagsIcons[FLAGS.wind].icon,
        color: flagsIcons[FLAGS.wind].color,
      },
      {
        label: t(FLAGS.tsunami),
        value: FLAGS.tsunami,
        icon: flagsIcons[FLAGS.tsunami].icon,
        color: flagsIcons[FLAGS.tsunami].color,
      },
      {
        label: t(FLAGS.flood),
        value: FLAGS.flood,
        icon: flagsIcons[FLAGS.flood].icon,
        color: flagsIcons[FLAGS.flood].color,
      },
      {
        label: t(FLAGS.fire),
        value: FLAGS.fire,
        icon: flagsIcons[FLAGS.fire].icon,
        color: flagsIcons[FLAGS.fire].color,
      },
    ],
    [t]
  );

  return flagsOptions;
}
