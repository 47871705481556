import React, { createContext, useState, useContext, useCallback, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { useHistory, useLocation } from "react-router-dom";

import { stopTracker } from "services/tracking/openreplayTracker";
import { showErrorToast, showSuccessToast } from "services/utils/toast";
import API from "api/apiClient";
import { Mixpanel } from "services/tracking/Mixpanel";
import { useStorage } from "services/hooks/useStorage";
import apm from "services/tracking/logger";

import { ROUTE_PATHS } from "../router";
import { useSession } from "./useSession";
import { initializeTracker } from "./initializeTracker";
import { postSignInAction } from "./postSignInAction";

const AuthContext = createContext();

export function AuthProvider({ children }) {
  const { t } = useTranslation("auth");
  const [user, setUser] = useState(null);
  const [isSignedIn, setIsSignedIn] = useStorage("isSignedIn", false);
  const [isAdmin, setIsAdmin] = useStorage("isAdmin", false);

  const history = useHistory();
  const { state } = useLocation();

  const invalidateSession = useCallback(() => {
    setIsSignedIn(false);
    setUser(null);
    setIsAdmin(false);
    stopTracker();
    apm.setUserContext({
      id: null,
      email: null,
      username: null,
    });
  }, [setIsSignedIn, setUser, setIsAdmin]);

  const updateAdminStatus = useCallback(async () => {
    try {
      await API.adminContactUs();
      setIsAdmin(true);
    } catch (error) {
      setIsAdmin(false);
    }
  }, [setIsAdmin]);

  const updateUserState = useCallback(
    async (userData) => {
      setUser(userData);
      apm.setUserContext({
        id: userData.customer_id,
        email: userData.email,
        username: userData.customer_id,
      });
      await updateAdminStatus();
    },
    [updateAdminStatus]
  );

  useSession({ isSignedIn, invalidateSession, updateUserState });

  const signInAction = useCallback(
    async (data) => {
      try {
        await API.login(data.email, data.password);

        window.dataLayer = window.dataLayer || [];
        window.dataLayer.push({ event: "sign_in" });

        Mixpanel.identify(data.email);
        Mixpanel.track("Successful regular login");
        Mixpanel.people.set({
          $email: data.email,
        });

        const response = await API.getUser();
        if (!response) return;

        await initializeTracker(response, isAdmin);
        setIsSignedIn(true);
        await updateUserState(response);
        postSignInAction(state, history);
      } catch (error) {
        Mixpanel.reset();
        Mixpanel.track("Unsuccessful regular login", { "From email": data.email });
        throw error?.response?.data;
      }
    },
    [isAdmin, updateUserState, setIsSignedIn, history, state]
  );

  const signOutAction = useCallback(async () => {
    try {
      await API.logout();
      showSuccessToast(t("You are sign out successfully!"));
      invalidateSession();
      history.push(ROUTE_PATHS.SIGN_IN);
    } catch (error) {
      showErrorToast(t("Something went wrong!"));
    }
  }, [t, history, invalidateSession]);

  const authContextValue = useMemo(
    () => ({
      user,
      isSignedIn,
      setIsSignedIn,
      isAdmin,
      setIsAdmin,
      signInAction,
      signOutAction,
    }),
    [user, isSignedIn, isAdmin, signInAction, signOutAction, setIsAdmin, setIsSignedIn]
  );

  return <AuthContext.Provider value={authContextValue}>{children}</AuthContext.Provider>;
}

export function useAuth() {
  return useContext(AuthContext);
}
