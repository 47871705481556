import React from "react";
import { createColumnHelper } from "@tanstack/react-table";

import { getTableIndex } from "components/presenters/special/Tables/tableColumns/helpers/index";
import { ALL_SORTING_COLUMNS } from "components/presenters/special/Tables/tableColumns/helpers/sortingColumns";
import { PROJECT_TYPES } from "services/constants/projects/project-types";

import { generateViewAddressColumn } from "../ui/ViewAddressColumn";
import { generateDataColumn } from "../ui/DataColumn";
import { generateFavoriteSortingColumn, generateSortingColumn } from "../ui/SortingColumns";
import { generateDeleteAddressColumn } from "../ui/DeleteAddressColumn";

const columnHelper = createColumnHelper();

export class ColumnsBuilder {
  constructor() {
    this.columns = [];
  }

  addColumn({ accessorKey, header, cell, size = 150, align = "center" }) {
    this.columns.push({ accessorKey, header, cell, size, align });
    return this;
  }

  addEditableColumn({ accessorKey, header }) {
    this.columns.push({ accessorKey, header });
    return this;
  }

  addNumberColumn({ header, pagination, isAdded = true }) {
    if (!isAdded) {
      return this;
    }
    this.columns.push({
      accessorKey: "number",
      header,
      cell: ({ row }) => (
        <div>
          <span>{getTableIndex(pagination.pageIndex, row.index)}</span>
        </div>
      ),
      size: 100,
    });
    return this;
  }

  addDataColumn({ header, isAdded = true }) {
    if (!isAdded) {
      return this;
    }
    this.columns.push(columnHelper.display(generateDataColumn({ header })));
    return this;
  }

  addViewAddressColumn({ header, href, projectId, projectType = PROJECT_TYPES.ADDRESS_ANALYSIS }) {
    this.columns.push(columnHelper.display(generateViewAddressColumn({ header, href, projectId, projectType })));
    return this;
  }

  addFavoriteColumn({ cellName, setAddressOptions, onFavoriteButtonClick }) {
    this.columns.push(
      columnHelper.display(
        generateFavoriteSortingColumn({
          accessorKey: "is_favorite",
          sortKey: "is_favorite",
          cellName,
          setAddressOptions,
          onFavoriteButtonClick,
        })
      )
    );
    return this;
  }

  addSortingColumns({
    countryValue,
    tooltipContent,
    setAddressOptions,
    language,
    thisColumns = ALL_SORTING_COLUMNS[countryValue],
  }) {
    const sortingColumns = thisColumns.map((item) => {
      return generateSortingColumn({
        accessorKey: item.field,
        sortKey: item.field,
        setAddressOptions,
        cellName: item.name[language],
        displayTooltip: item.field === "building_count",
        tooltipContent,
      });
    });

    this.columns = [...this.columns, ...sortingColumns];
    return this;
  }

  addDeleteColumn({ onConfirmDelete, projectId }) {
    this.columns.push(columnHelper.display(generateDeleteAddressColumn({ onConfirmDelete, projectId })));
    return this;
  }

  build() {
    return this.columns;
  }
}
