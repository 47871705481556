export const JAPAN_PREFECTURES = [
  { label: "北海道 (Hokkaido)", value: "北海道" },
  { label: "青森 (Aomori)", value: "青森" },
  { label: "岩手 (Iwate)", value: "岩手" },
  { label: "宮城 (Miyagi)", value: "宮城" },
  { label: "秋田 (Akita)", value: "秋田" },
  { label: "山形 (Yamagata)", value: "山形" },
  { label: "福島 (Fukushima)", value: "福島" },
  { label: "茨城 (Ibaraki)", value: "茨城" },
  { label: "栃木 (Tochigi)", value: "栃木" },
  { label: "群馬 (Gunma)", value: "群馬" },
  { label: "埼玉 (Saitama)", value: "埼玉" },
  { label: "千葉 (Chiba)", value: "千葉" },
  { label: "東京 (Tokyo)", value: "東京" },
  { label: "神奈川 (Kanagawa)", value: "神奈川" },
  { label: "新潟 (Niigata)", value: "新潟" },
  { label: "富山 (Toyama)", value: "富山" },
  { label: "石川 (Ishikawa)", value: "石川" },
  { label: "福井 (Fukui)", value: "福井" },
  { label: "山梨 (Yamanashi)", value: "山梨" },
  { label: "長野 (Nagano)", value: "長野" },
  { label: "岐阜 (Gifu)", value: "岐阜" },
  { label: "静岡 (Shizuoka)", value: "静岡" },
  { label: "愛知 (Aichi)", value: "愛知" },
  { label: "三重 (Mie)", value: "三重" },
  { label: "滋賀 (Shiga)", value: "滋賀" },
  { label: "京都 (Kyoto)", value: "京都" },
  { label: "大阪 (Osaka)", value: "大阪" },
  { label: "兵庫 (Hyogo)", value: "兵庫" },
  { label: "奈良 (Nara)", value: "奈良" },
  { label: "和歌山 (Wakayama)", value: "和歌山" },
  { label: "鳥取 (Tottori)", value: "鳥取" },
  { label: "島根 (Shimane)", value: "島根" },
  { label: "岡山 (Okayama)", value: "岡山" },
  { label: "広島 (Hiroshima)", value: "広島" },
  { label: "山口 (Yamaguchi)", value: "山口" },
  { label: "徳島 (Tokushima)", value: "徳島" },
  { label: "香川 (Kagawa)", value: "香川" },
  { label: "愛媛 (Ehime)", value: "愛媛" },
  { label: "高知 (Kochi)", value: "高知" },
  { label: "福岡 (Fukuoka)", value: "福岡" },
  { label: "佐賀 (Saga)", value: "佐賀" },
  { label: "長崎 (Nagasaki)", value: "長崎" },
  { label: "熊本 (Kumamoto)", value: "熊本" },
  { label: "大分 (Oita)", value: "大分" },
  { label: "宮崎 (Miyazaki)", value: "宮崎" },
  { label: "鹿児島 (Kagoshima)", value: "鹿児島" },
  { label: "沖縄 (Okinawa)", value: "沖縄" },
];
