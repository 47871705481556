import styled from "styled-components/macro";

import { COLORS, QUERIES, WEIGHT } from "configs/styles/common";

export const Breadcrumb = styled.div`
  justify-self: start;
  color: ${COLORS.DARK.Secondary};

  & svg {
    height: 18px;
    stroke-width: 2px;
  }

  & button {
    font-weight: ${WEIGHT.bold};
    color: ${COLORS.DARK.Secondary};
    font-size: var(--20px);
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 10px;

    @media ${QUERIES.upToTablet} {
      font-size: var(--16px);
    }
  }

  @media (max-width: 630px) {
    & span {
      display: none;
    }
  }
`;
