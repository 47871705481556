import React from "react";
import { useTranslation } from "react-i18next";
import { useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";

// import { useCountry } from "app/providers/business";
import { Button, BUTTON_VARIANT } from "components/presenters/common/Button";
import { countryForms } from "components/blocs/forms";

import { defaultInputValues, schemas } from "./schemas";
import { ModalMap } from "./ModalMap";
import * as Styled from "./style";

export function ModalForm() {
  const { t } = useTranslation("parcels-table");
  // const { country } = useCountry();
  const country = { value: "us" };

  const schema = schemas[country.value];
  const defaultValues = defaultInputValues[country.value];
  const SelectedForm = countryForms[country.value] || null;

  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: zodResolver(schema),
    mode: "onBlur",
    defaultValues,
  });

  function onSubmit(data) {
    console.log(data);
  }

  return (
    <Styled.Wrapper onSubmit={handleSubmit(onSubmit)} $country={country.value}>
      {SelectedForm && <SelectedForm control={control} errors={errors} />}

      <ModalMap />

      <Button type="submit" variant={BUTTON_VARIANT.CONTAINED}>
        {t("add building")}
      </Button>
    </Styled.Wrapper>
  );
}
