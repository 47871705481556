const fullAddressColumn = {
  field: "full_address",
  type: "text",
  name: {
    "en-US": "Address",
    ja: "住所",
  },
};

export const usSortingColumns = [
  fullAddressColumn,
  {
    field: "city",
    type: "text",
    name: {
      "en-US": "City",
      ja: "市",
    },
  },
  {
    field: "state",
    type: "dropdown",
    name: {
      "en-US": "State",
      ja: "都道府県",
    },
  },
  {
    field: "zip",
    type: "text",
    name: {
      "en-US": "Zip",
      ja: "郵便番号",
    },
  },
];

export const jpSortingColumns = [
  fullAddressColumn,
  {
    field: "prefecture",
    type: "dropdown",
    name: {
      "en-US": "Prefecture",
      ja: "都道府県",
    },
  },
];

export const ALL_SORTING_COLUMNS = {
  us: usSortingColumns,
  jp: jpSortingColumns,
  default: usSortingColumns,
};
