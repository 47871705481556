import React from "react";
import { useTranslation } from "react-i18next";

import { Dialog, DialogContent, DialogHeader, DialogTitle, DialogTrigger } from "components/presenters/common/Dialog";

import { AddFootprintButton } from "../AddFootprintButton";
import { ModalForm } from "./ModalForm";
import { dialogHeaderStyle, dialogTitleStyle } from "./style";

export function AddFootprintModal() {
  const { t } = useTranslation("parcels-table");

  return (
    <Dialog>
      <DialogTrigger>
        <AddFootprintButton />
      </DialogTrigger>

      <DialogContent aria-describedby={undefined} maxWidth={460}>
        <DialogHeader style={dialogHeaderStyle}>
          <DialogTitle style={dialogTitleStyle}>{t("Adding footprint")}</DialogTitle>
        </DialogHeader>

        <ModalForm />
      </DialogContent>
    </Dialog>
  );
}
