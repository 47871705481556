import { useEffect } from "react";
import { useLocation } from "react-router-dom";

import { refreshToken } from "api/basicConfig";
import API from "api/apiClient";

export function usePushNotifications({ setIsNewNotification, setIsPopupShown, setPopupData }) {
  const location = useLocation();

  useEffect(() => {
    const source = new EventSource("/api/push-notifications", {
      withCredentials: true,
    });

    source.addEventListener("unauthorized", function () {
      refreshToken()
        .then((data) => {
          if (data.status !== 200) {
            source.close();
          }
        })
        .catch(() => source.close());
    });

    source.addEventListener("new_notification_available", function (event) {
      const isNewNotificationAvailable = JSON.parse(event.data);
      setIsNewNotification(isNewNotificationAvailable);
    });

    source.addEventListener("new_popup_available", function (event) {
      const isNewPopupAvailable = JSON.parse(event.data);

      if (isNewPopupAvailable) {
        API.fetchPopup()
          .then(async (response) => {
            const nextPopupData = JSON.parse(await response.text()).data.popup;
            setPopupData(nextPopupData);

            if (nextPopupData && Object.keys(nextPopupData).length > 0) {
              setIsPopupShown(true);
            }
          })
          .catch((error) => console.error("Error fetching popup:", error));
      }
    });

    source.addEventListener("error", () => {
      source.close();
    });

    return () => {
      source.close();
    };
  }, [location.pathname, setIsNewNotification, setIsPopupShown, setPopupData]);
}
