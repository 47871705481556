import React from "react";
import { ArrowDownTrayIcon } from "@heroicons/react/24/outline";

import * as Styled from "./style";

export function ExportButton() {
  return (
    <Styled.ActionButton>
      <ArrowDownTrayIcon />
    </Styled.ActionButton>
  );
}
