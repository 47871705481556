import React from "react";
import { Redirect, Route } from "react-router-dom";

import { useLoadBootstrap } from "pages/admin/hooks/useLoadBootstrap";

import { ROUTE_PATHS } from "../../consts";
import { useAuth } from "../../../auth";
import { CountryProvider } from "../../../business";

export function AdminRoute({ children, ...rest }) {
  const auth = useAuth();
  const isAdmin = auth.isAdmin;
  useLoadBootstrap({ isAdmin });

  return (
    <Route
      {...rest}
      render={({ location }) =>
        isAdmin === true ? (
          <CountryProvider>{children}</CountryProvider>
        ) : (
          <Redirect
            to={{
              pathname: ROUTE_PATHS.SIGN_IN,
              state: { from: location },
            }}
          />
        )
      }
    />
  );
}
